.footer {
    background: #FFFFFF;
    box-shadow: 0 -.4rem 1.7rem rgba(155, 155, 155, 0.25);
    > .page-container {
        padding: 5rem 2rem;
    }
    /*
        ============================================================
        General
        ============================================================
    */
    &__title {
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 206%;
        color: #000000;
        text-transform: uppercase;
    }
    &__text {
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 206%;
        color: #606060;
    }
    /*
        ============================================================
        Body
        ============================================================
    */
    &__body {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        margin-bottom: 3.5rem;
    }

    &__left {
        display: flex;
        align-items: flex-start;
        margin-right: 10rem;
    }
    &__right {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-grow: 1;
    }

    &__logo {
        margin-right: 4rem;
        max-width: 13rem;
    }
    &__tagline {

    }

    &__soc-links {
        &-title {
            margin-bottom: .5em;
        }
        &__content {
            display: flex;
            align-items: center;
        }
    }
    &__soc-link {
        display: block;
        width: 3.3rem;

        margin-right: 1.7rem;
        &:last-child {
            margin-right: 0;
        }
    }

    &__requisites {

    }

    &__contacts {
        display: flex;
        flex-direction: column;

        //margin-left: 5rem;
    }
    &__phone {
        margin-bottom: .5rem;
    }

    /*
        ============================================================
        Bottom
        ============================================================
    */
    &__copyright {
        &-title {
            font-size: 1rem;
            line-height: 206%;
            color: #272727;

            margin-bottom: .5rem;
        }
        &-text {
            font-size: 1rem;
            line-height: 206%;
            color: #AFAFAF;
            max-width: 80rem;
        }
    }
}

@media screen and (max-width: 1240px) {
    .footer {
        &__body {
            flex-direction: column;
        }

        &__left {
            margin-right: 0;
            margin-bottom: 2.5rem;
        }
        &__right {
            width: 100%;
        }
    }
}
@media screen and (max-width: 992px) {
    .footer {
        &__body {

        }
        &__logo {

        }
    }
}
@media screen and (max-width: 768px) {
    .footer {
        &__body {

        }
        &__logo {

        }
        &__tagline {

        }
        &__requisites {

        }
        &__contacts {

        }
    }
}
@media screen and (max-width: 576px) {
    .footer {
        &__body {
            align-items: center;
        }
        &__left {

        }
        &__right {
            width: auto;
            align-items: center;
            flex-direction: column;
            text-align: center;
        }
        &__logo {
        }
        &__tagline {
            font-size: 1.2rem;
            max-width: 21rem;
            br {
                display: none;
            }
        }
        &__soc-links {
            margin-bottom: 2.5rem;
            &__content {
                justify-content: center;
            }
        }
        &__requisites {
            margin-bottom: 2.5rem;
        }
    }
}
@media screen and (max-width: 400px) {
    .footer {
        &__logo {
            margin-right: 1rem;
            flex: 0 0 11rem;
        }
    }
}
