.faq {
    padding-top: 6rem;
    padding-bottom: 6rem;
    &__title {
        margin-bottom: 9rem;
    }
}
.faq-accordion {
    max-width: 54.5rem;
    margin: 0 auto;
    .accordion-item {
        &.active {
            .accordion-item {
                &__icon {
                    transform: rotate(180deg);
                }
            }
        }

        &__header {
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 179.19%;
            color: #404040;

            padding: 1.1rem 5rem 1.1rem 0;
            border-top: .2rem solid #E1E1E1;
            position: relative;
            cursor: pointer;
        }
        &__icon {
            position: absolute;
            right: 0;
            //top: 50%;
            //transform: translateY(-50%);
            top: 1.1em;
        }
        &__panel {
            font-size: 1.2rem;
            line-height: 179.19%;
            color: #404040;

            padding-top: .3rem;
            padding-bottom: 3rem;
            padding-right: 6.8rem;

            display: none;
        }
    }
}

@media screen and (max-width: 1240px) {
    .faq {
        &__title {
            text-align: center;
        }
    }
}
@media screen and (max-width: 576px) {
    .faq-accordion {
        .accordion-item {
            &__header {
                font-size: 1.4rem;
            }
            &__panel {
                padding-right: 3rem;
            }
        }
    }
}
