.check-list {
    padding-top: 10.5rem;
    padding-bottom: 20.5rem;

    background-image: url('../img/background-1.jpg');
    background-size: auto 100%;
    background-position: center;
    background-repeat: repeat-x;

    position: relative;

    > .page-container {
        position: relative;
        z-index: 2;
    }

    &__title {
        margin-bottom: 12rem;
    }

    &__image {
        max-width: none;
        width: 124rem;
        height: 87rem;

        position: absolute;
        top: 0rem;
        right: -30rem;
    }
}

@media screen and (max-width: 1240px) {
    .check-list {
        &__image {
            top: 0rem;
            right: -50rem;
        }
    }
}
@media screen and (max-width: 992px) {
    .check-list {
        $check-list-image-koef: 1.5;
        &__image {
            top: 15rem;
            right: -35rem;

            width: 124rem / $check-list-image-koef;
            height: 87rem / $check-list-image-koef;
        }
    }
}
@media screen and (max-width: 768px) {
    .check-list {
        > .page-container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__title {
            margin-bottom: 0;
            order: 1;
            br {
                display: none;
            }
        }
        &__image {
            position: static;
            width: auto;
            height: auto;
            max-width: 100%;
            order: 2;
            //right: calc(50% - 5rem);
            //transform: translateX(50%);
            //top: auto;
            //bottom: -55rem;
        }
        &__form {
            order: 3;
        }
    }
}
@media screen and (max-width: 576px) {
    .check-list {
        &__title {
            text-align: center;
        }
    }
}
