.guarantee {
    padding-top: 6rem;
    padding-bottom: 6rem;
    &__title {
        margin-bottom: 9rem;
    }

    &__content {
        display: flex;

    }
    &__list {
        flex: 0 0 50%;
        margin-right: 2.4rem;
    }
    &__image {
        flex: 0 0 50%;
    }
}
.guarantee-list-item {
    background: #FFFFFF;
    box-shadow: 0 .4rem 1.7rem rgba(0, 0, 0, 0.08);
    border-radius: .8rem;

    padding: 2.25rem 5.5rem 2rem 3rem;

    margin-bottom: 2.2rem;
    &:last-child {
        margin-bottom: 0;
    }

    position: relative;
    &__number {
        width: 3.8rem;
        height: 3.8rem;
        background: #FF9B00;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        font-weight: 900;
        font-size: 1.6rem;
        line-height: 206%;
        color: #FFFFFF;

        position: absolute;
        left: -1.7rem;
        top: -1.4rem;
    }
    &__title {
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 206%;
        color: #1F1F1F;

        margin-bottom: .5rem;
    }
    &__text {
        font-size: 1.2rem;
        line-height: 206%;
        color: #1F1F1F;
    }
}

@media screen and (max-width: 1240px) {
    .guarantee {
        &__content {
            display: flex;
            flex-direction: column;
        }
        &__list {
            margin-bottom: 5rem;
            margin-right: 0;
        }
        &__image {
            text-align: center;
        }
    }
}
@media screen and (max-width: 992px) {
    .guarantee {
        &__title {
            text-align: center;
            br {
                display: none;
            }
        }
    }
}
@media screen and (max-width: 576px) {
    .guarantee {

    }
    .guarantee-list-item {
        padding-top: 2.5rem;
        &__number {
            left: -.75rem;
        }
    }
}
