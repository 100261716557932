.consult {
    > .page-container {
        position: relative;
        padding-top: 10rem;
        padding-bottom: 4rem;
    }

    position: relative;
    &__background-image {
        height: 96.5rem;
        bottom: 22rem;
    }
    &__cloud {
        bottom: 0;
        z-index: 1;

        height: 65rem;
    }

    &__main {
        margin-bottom: 4rem;
    }

    &__content {
        max-width: 53rem;
    }
    &__title {
        margin-bottom: 1rem;
    }
    &__subtitle {
        margin-bottom: 1.5rem;
    }

    &__steps {
        position: relative;
        z-index: 1;
        &-title {
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 206%;
            color: #686868;

            margin-bottom: 3.5rem;
        }
        &-items {

        }
    }

    &__form {
        margin: 0 auto;
    }

    &__consultant {
        position: absolute;
        top: 0;
        right: -5rem;

        width: 59rem;
    }
}
.consult-step {
    position: relative;

    background: #FFFFFF;
    box-shadow: 0 .4rem 1.7rem rgba(0, 0, 0, 0.08);
    border-radius: .8rem;

    padding: 2.5rem 3rem;

    font-size: 1.4rem;
    line-height: 206%;
    color: #1F1F1F;

    margin-bottom: 1.3rem;
    &:last-child {
        margin-bottom: 0;
    }

    &__number {
        width: 3.8rem;
        height: 3.8rem;

        font-weight: 900;
        font-size: 1.6rem;
        line-height: 206%;
        color: #FFFFFF;

        background: #FF9B00;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: -1.4rem;
        left: -1.7rem;
    }

    &__title {
        font-weight: 700;
    }

    &__br-mobile {
        display: none;
    }
}

@media screen and (max-width: 1240px) {
    .consult {
        &__main {
            position: relative;
        }
        &__consultant {
            width: 47rem;
            top: auto;
            bottom: -6.25rem;
            right: -10rem;
            .person {
                &__image {
                    margin-left: 4rem;
                }
            }
        }
    }
}
@media screen and (max-width: 992px) {
    .consult {
        &__cloud {
            display: none;
        }
        &__main {
            position: static;
        }
        &__consultant {
            width: 38.5rem;
            font-size: .8rem;
            z-index: 2;
            right: -12.5rem;
            .person {
                &__image {
                    width: 30rem;
                }
            }
        }
        &__form {
            margin: 0;
        }
    }
}
@media screen and (max-width: 768px) {
    .consult {
        &__main {
            margin-bottom: 2.5rem;
        }
        &__content {
            margin: 0 auto;
            margin-bottom: 2.5rem;
        }
        &__consultant {
            position: static;
            margin: 0 auto;
        }
        &__form {
            margin: 0 auto;
        }
    }
}
@media screen and (max-width: 576px) {
    .consult {
        &__main {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__steps-title {
            font-size: 1.4rem;
        }
        &__consultant {
            margin: 0;
        }
    }
    .consult-step {
        padding-top: 3rem;
        font-size: 1.2rem;
        &__number {
            left: -.75rem;
        }
        &__br-mobile {
            display: block;
        }
    }
}
