.calculate {
    padding-top: 4rem;
    padding-bottom: 6rem;
    &__title {
        font-size: 1.6rem;
        line-height: 206%;
        color: #1F1F1F;

        margin-bottom: 1.5rem;

        text-align: center;
    }
    &__form {
        margin: 0 auto;
    }
}

@media screen and (max-width: 576px) {
    .calculate {
        &__title {
            font-size: 1.4rem;
        }
    }
}
