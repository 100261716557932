.advantages-2 {
    position: relative;
    &__background-image {
        bottom: 13.6rem;
        height: 96.5rem;
    }
    &__cloud {
        z-index: 1;
        bottom: -3rem;

        height: 44rem;

    }

    &__title {
        margin-bottom: 3.5rem;
    }
    &__items {
        position: relative;

        display: flex;
        justify-content: center;

        padding-top: 32rem;

        background-image: url('../img/circle.svg');
        background-position: center 5.5rem;
        background-repeat: no-repeat;
        background-size: 93rem 93rem;
    }

    &__person {
        width: 47rem;
        margin-left: -3rem;

        position: relative;
        z-index: 1;

        &:before {
            content: '';

            width: 57rem;
            height: 23rem;

            background-image: url('../img/background-2.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            position: absolute;
            left: calc(50% + 1rem);
            top: 12rem;
            transform: translateX(-50%);

            z-index: -1;
        }

        .person {
            &__image {
                width: 35.5rem;
                margin-left: 15%;
                margin-bottom: -3rem;
            }
        }
    }
}
.advantage-2 {

    position: absolute;
    z-index: 2;
    top: 0;

    width: 19rem;
    &__image {
        width: 19rem;
        height: 19rem;
        filter: drop-shadow(0 .4rem 1rem rgba(0, 0, 0, 0.08));
        border-radius: 1.2rem;

        margin-bottom: 1.6rem;
    }
    &__text {
        font-size: 1.6rem;
        line-height: 206%;
        color: #1F1F1F;

        white-space: nowrap;

        padding: 0 1rem;

        display: flex;
        justify-content: center;
    }

    &-1 {
        left: 0;
        top: 40rem;
    }
    &-2 {
        left: 10%;
        top: 10%;
    }
    &-3 {
        top: 0;
        left: calc(50% - 19rem - 3rem);
    }
    &-4 {
        top: 0;
        right: calc(50% - 19rem - 3rem);
    }
    &-5 {
        right: 10%;
        top: 10%;
    }
    &-6 {
        right: 0;
        top: 40rem;
    }
}


@media screen and (max-width: 1240px) {
    .advantage-2 {
        &-2 {
            left: 2.5%;
            top: 12.5%;
        }
        &-5 {
            right: 2.5%;
            top: 12.5%;
        }
    }
}
@media screen and (max-width: 992px) {
    .advantages-2 {
        &__items {
            padding-top: 0;
            flex-flow: row wrap;

            background: none;
        }
        &__person {

        }
    }
    .advantage-2 {
        position: static;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 2rem;
    }
}
@media screen and (max-width: 576px) {
    .advantages-2 {
        &__person {
            font-size: .8rem;
            width: 37rem;
            .person {
                &__image {
                    width: 20rem;
                    margin-left: 9.5rem;
                }
            }

            $advantages-2-before-image-koef: 1.5;
            &:before {
                width: 57rem / $advantages-2-before-image-koef;
                height: 23rem / $advantages-2-before-image-koef;
            }
        }
    }
    .advantage-2 {
        width: 13rem;
        &__image {
            width: 13rem;
            height: 13rem;
        }
        &__text {
            font-size: 1.2rem;
        }
    }
}
