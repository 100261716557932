.quiz-block {
    padding-top: 6rem;
    padding-bottom: 13rem;
    &__title {
        margin-bottom: 10rem;
        &:before,
        &:after {
            display: none;
        }
    }
    &__subtitle {
        margin-top: .5rem;
    }
    &__quiz {
        display: flex;
        justify-content: center;
    }
}
