.brands {
    padding-top: 6rem;
    padding-bottom: 5rem;

    position: relative;
    &__background-image {
        height: 96.5rem;
        top: auto;
        bottom: 32rem;
    }

    &__title {
        margin-bottom: 6rem;
    }

    &__content {

    }

    &__list {
        display: flex;
        align-items: center;
        justify-content: center;

        margin-bottom: 4.5rem;
    }
    &__list-item {
        filter: drop-shadow(0 .4rem 1.7rem rgba(0, 0, 0, 0.08));

        width: 13rem;
        height: 13.8rem;

        cursor: pointer;

        background: #fff;
        border-radius: .4rem;
        padding: .75rem;

        img {
            object-fit: contain;

            width: 100%;
            height: 100%;
            border-radius: 4px;
        }

        margin-right: 1.3rem;
        &:last-child {
            margin-right: 0;
        }
    }

    &__work {
        width: 78rem;
        margin: 0 auto;
    }
    &__carousel {
        margin-bottom: 2rem;
        &-item {
            width: 78rem;
            height: 50rem;

            display: flex;
            align-items: center;
            justify-content: center;

            img {
                border-radius: 1.1rem;

                width: auto !important;
                max-width: 100%;
                max-height: 100%;
            }
        }
        &-dots {

            position: absolute;
            left: 0;
            top: 0;

            transition: left .25s, right .25s;

            width: 100%;
            height: 100%;

            display: flex;

            &-wrapper {
                width: 100%;
                height: 11.5rem;
                margin-bottom: 4rem;
                overflow: hidden;

                position: relative;
            }
        }
        &-dot {
            cursor: pointer;

            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: .3rem;
            overflow: hidden;
            img {
                min-width: 100%;
                min-height: 100%;
            }

            margin: 0 1.2rem;
            flex: 0 0 calc(25% - 1.2*2rem);
            height: 100%;
        }

        .owl {
            &-prev,
            &-next {
                width: 4.6rem;
                height: 4.6rem;

                background: #FFFFFF !important;
                box-shadow: 0 .4rem .9rem rgba(0, 0, 0, 0.08);

                display: flex;
                align-items: center;
                justify-content: center;

                position: absolute;
                top: 50%;
            }
            &-prev {
                right: calc(100% + 8.5rem);
                img {
                    transform: scale(-1, 1);
                }
            }
            &-next {
                left: calc(100% + 8.5rem);
            }
        }
    }
    &__work-description {
        font-size: 16px;
        line-height: 206%;
        color: #1F1F1F;
        .bold {
            font-weight: 700;
        }
    }
}

@media screen and (max-width: 1240px) {
    .brands {
        &__carousel {
            .owl {
                &-prev {
                    right: calc(100% + 3rem);
                    img {
                        transform: scale(-1, 1);
                    }
                }
                &-next {
                    left: calc(100% + 3rem);
                }
            }
        }
    }
}
@media screen and (max-width: 992px) {
    .brands {
        &__list {
            flex-flow: row wrap;
            &-item {
                margin-left: .5rem;
                margin-right: .5rem;
                margin-bottom: 1rem;
                &:last-child {
                    margin-right: .5rem;
                }
            }
        }


        &__work {
            width: 100%;
        }
        &__work-description {
            padding: 0 5rem;
        }
        &__carousel {
            padding: 0 5rem;
            &-item {
                width: 100%;
            }
            &-dots {

            }

            .owl {
                &-prev {
                    right: calc(100% - 4rem);
                }
                &-next {
                    left: calc(100% - 4rem);
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .brands {
        &__carousel {
            &-dots {
                &-wrapper {
                    height: 9rem;
                }
            }
        }
    }
}
@media screen and (max-width: 576px) {
    .brands {
        &__work {
            padding-top: 6rem;
        }
        &__work-description {
            padding: 0;
            font-size: 1.4rem;
        }
        &__carousel {
            padding: 0;
            &-item {
                height: 40rem;
            }

            &-dots {
                padding: 0;
                margin-bottom: 2rem;

                height: 7rem;
            }
            &-dot {
                margin: 0 .6rem;
                flex: 0 0 calc(25% - .6*2rem);
            }

            .owl {
                &-prev,
                &-next {
                    top: -4.6rem - 1rem;
                }
                &-prev {
                    right: calc(50% + 1rem);
                }
                &-next {
                    left: calc(50% + 1rem);
                }
            }
        }
    }
}
