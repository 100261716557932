.portfolio {
    padding-top: 9.25rem;
    padding-bottom: 4.5rem;
    position: relative;

    &__background-image {
        top: auto;
        bottom: 9rem;
        height: 78rem;
    }
    &__title {
        flex-direction: column;
        align-items: center;

        margin-right: -3rem;
        .subtitle {
            font-family: 'Gotham Pro', sans-serif;
            text-transform: none;
            font-weight: 400;
        }

        margin-bottom: 5rem;
    }
    &__items {
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;

        margin-bottom: 2.5rem;
    }
    &__items-more {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 206%;
        color: #FF9B00;
        text-transform: uppercase;

        text-align: center;

        cursor: pointer;

        span {
            position: relative;
            &:after {
                content: '';

                height: .2rem;
                width: 100%;

                background-image: url('../img/dots.svg');
                background-position: .2rem 0;
                background-repeat: repeat-x;
                background-size: contain;

                position: absolute;
                top: calc(100% + .5rem);
                left: 0;
            }
        }
    }
}
.portfolio-item {
    width: calc(33.33% - 2.5*2rem/3);
    max-width: 40.5rem;



    margin-bottom: 4rem;

    display: none;
    &.active {
        display: flex;
    }

    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__gallery {
        background: #FFFFFF;
        box-shadow: .6rem .6rem 2.1rem rgba(91, 91, 91, 0.27);
        border-radius: 1.4rem;

        padding: 2.5rem;

        display: grid;
        grid-gap: 2rem;
        grid-template-columns: repeat(3, 1fr);

        margin-bottom: 2.5rem;

        width: 100%;
        height: 2.5rem + 25rem + 2rem + 7.5rem + 2.5rem;
    }
    &__selected-image {
        grid-column: 1/4;
        height: 25rem;

        display: flex;
        align-items: center;
        justify-content: center;

        overflow: hidden;

        img {
            max-width: 100%;
            max-height: 25rem;
        }
    }
    &__image {
        cursor: pointer;

        width: 100%;
        max-height: 7.5rem;

        display: flex;
        align-items: center;
        justify-content: center;

        overflow: hidden;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    &__characteristics {
        max-width: 35rem;
    }
    &__characteristic {
        font-size: 16px;
        line-height: 206%;
        color: #1F1F1F;
        &-name {
            font-weight: 700;
        }
        &-value {

        }
    }
}

@media screen and (max-width: 992px) {
    .portfolio {
        &__title {
            margin-right: -5rem;
        }
        &__items {
            margin-bottom: 2.5rem;
        }
    }
    .portfolio-item {
        width: calc(50% - 2.5*1rem/2);
        margin-bottom: 4rem;
        &__gallery {
            grid-gap: 1rem;
            height: 2.5rem + 20rem + 1rem + 7.5rem + 2.5rem;
        }
        &__selected-image {
            max-height: 20rem;
            img {
                max-height: 20rem;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .portfolio {
        &__title {
            margin-right: 0;
        }
    }
}
@media screen and (max-width: 576px) {
    .portfolio {
        &__items {
            justify-content: center;
        }
        &__items-more {
            font-size: 1.4rem;
        }
    }
    .portfolio-item {
        width: 100%;
        &__characteristic {
            font-size: 1.4rem;
        }
    }
}
