.map {
    position: relative;

    padding-top: 8.5rem;
    padding-bottom: 3.5rem;

    &__iframe {
        position: absolute;
        left: 0;
        top: 0;

        width: 100%;
        height: 100%;

        background-image: url('../img/map.jpg');
        background-size: auto 100%;
        background-position: center;
        background-repeat: repeat-x;

        z-index: 1;

        iframe {
            width: 100%;
            height: 100%;
        }
    }

    &__content {

    }
    &__card {
        background: #FFFFFF;
        box-shadow: 0 .4rem 1.7rem rgba(0, 0, 0, 0.08);
        border-radius: .8rem;

        padding: 3.5rem 4.5rem 2rem;

        width: 51rem;

        position: relative;
        z-index: 2;
    }
    &__general {
        margin-bottom: 1rem;
    }
    &__other {

    }

    &__title {
        margin-bottom: .4rem;
    }
    &__general-subtitle {
        margin-bottom: .4rem;
    }
    &__subtitle {
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 206%;
        color: #1F1F1F;
    }
    &__address {
        font-size: 1.6rem;
        line-height: 206%;
        color: #6C6C6C;
        a {
            color: #6C6C6C;
        }
    }
    &__text {
        font-size: 1.6rem;
        line-height: 206%;
        color: #1F1F1F;

        margin-bottom: 1.2rem;
    }
}

@media screen and (max-width: 576px) {
    .map {
        display: flex;
        flex-direction: column-reverse;
        padding-top: 0;
        &__iframe {
            position: static;
            height: 50rem;
            background-position: calc(100% + 5rem) 0;
        }

        &__content {
            margin-bottom: 5rem;
        }
        &__card {
            width: auto;
            max-width: 50rem;
            padding: 2rem 2rem 2rem;
        }
        &__subtitle {
            font-size: 1.4rem;
        }
        &__address {
            font-size: 1.4rem;
        }
        &__text {
            font-size: 1.4rem;
        }
    }
}
@media screen and (max-width: 500px) {
    .map {
        &__iframe {
            height: 30rem;

            background-size: auto 125%;
            background-position: calc(100% + 7.5rem) center;
        }
    }
}
