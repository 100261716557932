.post-scriptum {
    &__help {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-bottom: 8.5rem;
    }
    &__title {
        margin-bottom: 6rem;
    }
    &__help-button {
        position: relative;
        margin-left: -2rem;
        &:before {
            content: '';

            width: 8.5rem;
            height: 8.5rem;

            background-image: url('../img/line-1.svg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            position: absolute;
            left: calc(100% + 1.7rem);
            top: -5rem;
        }
    }

    position: relative;
    &__background-image {
        height: 96.5rem;
    }
}
.post-scriptum-card {
    max-width: 90rem;
    margin: 0 auto;

    position: relative;
    z-index: 1;

    margin-bottom: 8.5rem;
    &:before {
        content: '';

        width: 96.5%;
        height: 3.2rem;

        position: absolute;
        left: 1.75%;
        bottom: -1.6rem;

        background: #FFFFFF;
        box-shadow: 0 .4rem 1.7rem rgba(0, 0, 0, 0.08);
        border-radius: .8rem;

        z-index: -1;
    }
    &__content {
        background: #FFFFFF;
        box-shadow: 0 .4rem 1.7rem rgba(0, 0, 0, 0.08);
        border-radius: .8rem;
        padding: 3rem 13rem;
    }

    &__title {
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 206%;
        color: #FF9B00;

        margin-bottom: 2em;
    }
    &__text {
        font-size: 1.6rem;
        line-height: 206%;
        color: #1F1F1F;
    }
    &__soc-links {
        display: flex;
        justify-content: center;
        margin-top: 4rem;
    }
    &__soc-link {
        display: block;
        width: 5rem;
        margin-right: 1rem;
        img {
            width: 100%;
        }
    }
}
@media screen and (max-width: 768px) {
    .post-scriptum-card {
        &__content {
            padding: 3rem 5rem;
        }
    }
}
@media screen and (max-width: 576px) {
    .post-scriptum {
        &__title {
            text-align: center;
        }
        &__help-button {
            margin-left: 0;
        }
    }
    .post-scriptum-card {
        &__content {
            padding: 3rem 3rem;
        }
        &__title {
            font-size: 1.4rem;
        }
        &__text {
            font-size: 1.4rem;
        }

        &__soc-links {

        }
        &__soc-link {
            width: 4rem;
        }
    }
}
