.person {
    font-size: 1rem;
    &__image {
        width: 100%;
    }
    &__name {
        font-family: 'Lora', serif;
        font-style: normal;
        font-weight: 700;
        font-size: 2.2em;
        line-height: 206%;
        text-transform: uppercase;
        color: #000000;

        width: 47/2.2 * 1em;
        height: 7/2.2 * 1em;

        display: flex;
        align-items: center;
        justify-content: center;

        margin-top: -(1/2.2) * 1em;
        margin-bottom: 1.75/2.2 * 1em;

        position: relative;
        z-index: 1;
        &:before {
            content: '';

            width: 48/2.2 * 1em;
            height: 10/2.2 * 1em;

            background-image: url('../img/panel-2.svg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            position: absolute;
            top: -(1/2.2) * 1em;
            left: -(.5/2.2) * 1em;

            z-index: -1;
        }
    }
    &__status {
        font-weight: 500;
        font-size: 1.4em;
        line-height: 206%;
        color: #797979;

        width: 30.5/1.4 * 1em;
        height: 4.6/1.4 * 1em;

        display: flex;
        align-items: center;
        justify-content: center;

        margin-left: 17/1.4 * 1em;

        position: relative;
        z-index: 1;
        &:before {
            content: '';

            width: 31.5/1.4 * 1em;
            height: 7.2/1.4 * 1em;

            background-image: url('../img/panel-3.svg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            position: absolute;
            top: -(.8/1.4) * 1em;
            left: -(.5/1.4) * 1em;

            z-index: -1;
        }
    }
}
