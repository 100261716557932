.intro {
    padding-top: 4rem;
    padding-bottom: 3rem;
    > .page-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__text-block {
        max-width: 53rem;

        background: #FFFFFF;
        box-shadow: 0 .4rem 1.7rem rgba(0, 0, 0, 0.08);
        border-radius: .8rem;

        padding: 5rem 5.5rem;
        margin-right: 2.75rem;
    }
    &__title {
        margin-bottom: 3.5rem;
        .thin {
            font-weight: 400;
        }
    }
    &__list {
        &-item {
            padding-left: 5.2rem + 2.8rem;
            font-size: 1.6rem;
            line-height: 206%;
            color: #000000;

            margin-bottom: 1.25rem;
            &:last-child {
                margin-bottom: 0;
            }

            a {
                font-weight: 700;
                color: #FF9B00;
            }

            position: relative;
            &:before {
                content: '';

                width: 5.2rem;
                height: .2rem;
                background: #FBBF62;

                position: absolute;
                left: 0;
                top: 1em;
            }
        }
    }
    &__person {
        width: 47rem;
        .person__image {
            width: 28rem;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

@media screen and (max-width: 992px) {
    .intro {
        padding-bottom: 8rem;
        > .page-container {
            flex-direction: column-reverse;
        }
        &__person {
            margin-bottom: 5rem;
        }
    }
}
@media screen and (max-width: 576px) {
    .intro {
        &__person {
            font-size: .8rem;
            width: 38rem;
        }
        &__text-block {
            padding: 2.5rem 2.5rem;
            margin: 0 auto;
        }
        &__title {
            margin-bottom: 2.5rem;
        }
        &__list {
            &-item {
                font-size: 1.2rem;
                padding-left: 3rem + 1.2rem;
                &:before {
                    width: 3rem;
                }
            }
        }
    }
}
