.input {
    background: #F3F4F8;
    border-radius: 3.8rem;

    display: flex;
    align-items: center;

    padding: 0 2.25rem;

    &__icon-wrapper {
        width: 3.6rem;
        height: 3.6rem;
        background: #FFFFFF;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 3.6rem;

        margin-right: 1.7rem;
    }
    &__icon {
        img {

        }
    }
    &__field {
        background: none;
        border: none;

        font-size: 1.6rem;
        line-height: 206%;
        color: #717070;

        &::placeholder {
            color: #BDBDBD;
        }

        padding: 2.25rem 0;

        outline: none;
        &:-webkit-autofill {
            background: none;
        }
    }
    textarea {
        resize: none;
        height: 14.5rem;
        width: 100%;
        &::placeholder {
            text-align: center;
        }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}
.input-file {
    cursor: pointer;
    input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    &__text {
        width: 20.2rem;
        color: #BDBDBD;
    }
}

@media screen and (max-width: 576px) {
    .input {
        &__field {
            font-size: 1.3rem;
        }
    }
}
