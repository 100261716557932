/*
    ============================================================
    Form Panel
    ============================================================
*/
.form-panel {
    max-width: 102rem;
    &__content {
        background: #FFFFFF;
        box-shadow: 0 .4rem 1.7rem rgba(0, 0, 0, 0.08);
        border-radius: .8rem;

        padding: 3rem 5rem;

        display: flex;
        align-items: flex-start;
        justify-content: center;
    }
    &__field {
        margin-right: 2.25rem;
    }
    &__note {
        font-size: 1rem;
        line-height: 206%;
        color: #AFAFAF;

        position: absolute;
        top: calc(100% + 1.8rem);
        left: 6rem;
    }

    position: relative;
    z-index: 1;
    &:after {
        content: '';

        background: #FFFFFF;
        box-shadow: 0 .4rem 1.7rem rgba(0, 0, 0, 0.08);
        border-radius: .8rem;

        width: 98/102 * 100%;
        height: 2rem;

        position: absolute;
        left: (102 - 98) / 102 / 2 * 100%;
        bottom: -1rem;

        z-index: -1;
    }

    &__error-message {
        text-align: center;
        margin-top: 1rem;
    }
}
/*
    ============================================================
    Form Card
    ============================================================
*/
.form-card {
    width: 36rem;

    position: relative;
    z-index: 1;
    &:after {
        content: '';

        position: absolute;
        height: 3.6rem;
        width: 34.5/36 * 100%;
        left: (36-34.5) / 36 / 2 * 100%;
        bottom: -1.8rem;

        background: #FFFFFF;
        box-shadow: 0 .4rem 1.7rem rgba(0, 0, 0, 0.08);
        border-radius: .8rem;

        z-index: -1;
    }

    &__content {
        background: #FFFFFF;
        box-shadow: 0 .4rem 1.7rem rgba(0, 0, 0, 0.08);
        border-radius: .8rem;

        padding: 3.8rem 3.7rem 3rem;
    }
    &__title {
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 206%;
        color: #606060;

        text-align: center;
        margin-bottom: 3rem;
    }
    &__input {
        margin-bottom: 2rem;
    }
    &__button {
        margin-bottom: 1.2rem;
    }
    &__hint {
        font-size: 1rem;
        line-height: 206%;
        color: #AFAFAF;

        width: 20rem;
        margin: 0 auto;
    }

    &__error-message {
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}


@media screen and (max-width: 1240px) {
    .form-panel {
        max-width: 40rem;
        &__content {
            flex-direction: column;
            align-items: center;
        }
        &__field {
            margin-right: 0;
            margin-bottom: 2rem;
        }
    }
}
@media screen and (max-width: 576px) {
    .form-panel {
        &__content {
            padding: 3rem 2.5rem;
        }
        &__field {
            width: 100%;
            max-width: 29rem;
        }
        &__button {
            width: 100%;
            max-width: 29rem;
        }
    }
    .form-card {
        width: auto;
        max-width: 36rem;
        &__content {
            padding: 2.5rem 2.5rem 2rem;
        }
        &__input {
            width: 100%;
        }
        &__button {
            width: 100%;
        }
    }
}
@media screen and (max-width: 400px) {
    .form-panel {
        &__content {
            padding: 3rem 1rem;
        }
        &__note {
            width: 80%;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
