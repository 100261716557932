@charset "UTF-8";
@import url(../local_modules/owl.carousel/dist/assets/owl.carousel.min.css);
@import url(../local_modules/jquery-modal/jquery.modal.min.css);
.input {
  background: #F3F4F8;
  border-radius: 3.8rem;
  display: flex;
  align-items: center;
  padding: 0 2.25rem;
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */ }
  .input__icon-wrapper {
    width: 3.6rem;
    height: 3.6rem;
    background: #FFFFFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 3.6rem;
    margin-right: 1.7rem; }
  .input__field {
    background: none;
    border: none;
    font-size: 1.6rem;
    line-height: 206%;
    color: #717070;
    padding: 2.25rem 0;
    outline: none; }
    .input__field::placeholder {
      color: #BDBDBD; }
    .input__field:-webkit-autofill {
      background: none; }
  .input textarea {
    resize: none;
    height: 14.5rem;
    width: 100%; }
    .input textarea::placeholder {
      text-align: center; }
  .input input::-webkit-outer-spin-button,
  .input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .input input[type=number] {
    -moz-appearance: textfield; }

.input-file {
  cursor: pointer; }
  .input-file input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1; }
  .input-file__text {
    width: 20.2rem;
    color: #BDBDBD; }

@media screen and (max-width: 576px) {
  .input__field {
    font-size: 1.3rem; } }

.lines {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(../img/line.png);
  background-size: 20rem auto;
  background-position: 0 0;
  background-repeat: repeat;
  z-index: 1; }

/*
    ============================================================
    Form Panel
    ============================================================
*/
.form-panel {
  max-width: 102rem;
  position: relative;
  z-index: 1; }
  .form-panel__content {
    background: #FFFFFF;
    box-shadow: 0 0.4rem 1.7rem rgba(0, 0, 0, 0.08);
    border-radius: .8rem;
    padding: 3rem 5rem;
    display: flex;
    align-items: flex-start;
    justify-content: center; }
  .form-panel__field {
    margin-right: 2.25rem; }
  .form-panel__note {
    font-size: 1rem;
    line-height: 206%;
    color: #AFAFAF;
    position: absolute;
    top: calc(100% + 1.8rem);
    left: 6rem; }
  .form-panel:after {
    content: '';
    background: #FFFFFF;
    box-shadow: 0 0.4rem 1.7rem rgba(0, 0, 0, 0.08);
    border-radius: .8rem;
    width: 96.07843%;
    height: 2rem;
    position: absolute;
    left: 1.96078%;
    bottom: -1rem;
    z-index: -1; }
  .form-panel__error-message {
    text-align: center;
    margin-top: 1rem; }

/*
    ============================================================
    Form Card
    ============================================================
*/
.form-card {
  width: 36rem;
  position: relative;
  z-index: 1; }
  .form-card:after {
    content: '';
    position: absolute;
    height: 3.6rem;
    width: 95.83333%;
    left: 2.08333%;
    bottom: -1.8rem;
    background: #FFFFFF;
    box-shadow: 0 0.4rem 1.7rem rgba(0, 0, 0, 0.08);
    border-radius: .8rem;
    z-index: -1; }
  .form-card__content {
    background: #FFFFFF;
    box-shadow: 0 0.4rem 1.7rem rgba(0, 0, 0, 0.08);
    border-radius: .8rem;
    padding: 3.8rem 3.7rem 3rem; }
  .form-card__title {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 206%;
    color: #606060;
    text-align: center;
    margin-bottom: 3rem; }
  .form-card__input {
    margin-bottom: 2rem; }
  .form-card__button {
    margin-bottom: 1.2rem; }
  .form-card__hint {
    font-size: 1rem;
    line-height: 206%;
    color: #AFAFAF;
    width: 20rem;
    margin: 0 auto; }
  .form-card__error-message {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem; }

@media screen and (max-width: 1240px) {
  .form-panel {
    max-width: 40rem; }
    .form-panel__content {
      flex-direction: column;
      align-items: center; }
    .form-panel__field {
      margin-right: 0;
      margin-bottom: 2rem; } }

@media screen and (max-width: 576px) {
  .form-panel__content {
    padding: 3rem 2.5rem; }
  .form-panel__field {
    width: 100%;
    max-width: 29rem; }
  .form-panel__button {
    width: 100%;
    max-width: 29rem; }
  .form-card {
    width: auto;
    max-width: 36rem; }
    .form-card__content {
      padding: 2.5rem 2.5rem 2rem; }
    .form-card__input {
      width: 100%; }
    .form-card__button {
      width: 100%; } }

@media screen and (max-width: 400px) {
  .form-panel__content {
    padding: 3rem 1rem; }
  .form-panel__note {
    width: 80%;
    left: 50%;
    transform: translateX(-50%); } }

.person {
  font-size: 1rem; }
  .person__image {
    width: 100%; }
  .person__name {
    font-family: 'Lora', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.2em;
    line-height: 206%;
    text-transform: uppercase;
    color: #000000;
    width: 21.36364em;
    height: 3.18182em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -0.45455em;
    margin-bottom: 0.79545em;
    position: relative;
    z-index: 1; }
    .person__name:before {
      content: '';
      width: 21.81818em;
      height: 4.54545em;
      background-image: url("../img/panel-2.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: -0.45455em;
      left: -0.22727em;
      z-index: -1; }
  .person__status {
    font-weight: 500;
    font-size: 1.4em;
    line-height: 206%;
    color: #797979;
    width: 21.78571em;
    height: 3.28571em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12.14286em;
    position: relative;
    z-index: 1; }
    .person__status:before {
      content: '';
      width: 22.5em;
      height: 5.14286em;
      background-image: url("../img/panel-3.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: -0.57143em;
      left: -0.35714em;
      z-index: -1; }

.js-form-success {
  display: none; }

.js-form-error .js-form__error-message {
  display: block; }

.js-form__success-message {
  display: none; }

.js-form__error-message {
  display: none;
  width: 100%;
  font-weight: 500;
  font-size: 1.6rem;
  text-align: left;
  color: red; }

.js-form .button:disabled {
  opacity: .5;
  cursor: not-allowed; }

.quiz {
  display: inline-block;
  position: relative;
  padding-left: 34rem;
  min-height: 62rem; }
  .quiz__sidebar {
    background: #FFFFFF;
    box-shadow: 0 0.4rem 1.6rem rgba(0, 0, 0, 0.08);
    border-radius: 1.2rem;
    position: absolute;
    left: 0;
    top: 0;
    width: 30rem;
    height: 62rem;
    padding: 4rem 3rem;
    border-left: 1rem solid #FF9B00; }
    .quiz__sidebar-title {
      font-weight: 700;
      font-size: 13px;
      line-height: 206%;
      color: #1F1F1F;
      margin-bottom: 2.5rem; }
  .quiz__present {
    margin-bottom: 2rem; }
    .quiz__present:last-child {
      margin-bottom: 0; }
    .quiz__present-text {
      font-size: 1.3rem;
      line-height: 206%;
      color: #1F1F1F; }
      .quiz__present-text .bold {
        font-weight: 700; }
      .quiz__present-text .orange {
        color: #FF9B00; }
    .quiz__present-image {
      margin-left: 2.5rem; }
    .quiz__present-1 .quiz__present-image {
      margin-left: 1.5rem; }
    .quiz__present-3 .quiz__present-image {
      margin-left: 1rem; }
  .quiz__main {
    width: 52.5rem;
    height: 100%;
    display: flex;
    flex-direction: column; }
  .quiz__progressbar {
    display: flex;
    align-items: center;
    margin-bottom: 3rem; }
    .quiz__progressbar-percent {
      font-size: 1.6rem;
      line-height: 206%;
      color: #1F1F1F;
      margin-right: 1.5rem; }
    .quiz__progressbar-background {
      height: 1.9rem;
      flex-grow: 1;
      max-width: 44rem;
      background: #EDEDED;
      border-radius: 1.7rem;
      position: relative; }
    .quiz__progressbar-body {
      position: absolute;
      left: 0;
      width: 0;
      top: 0;
      height: 100%;
      border-radius: 1.7rem;
      transition: width .25s;
      background-image: url("../img/button-texture.jpg");
      background-position: center;
      background-size: cover; }
      .quiz__progressbar-body:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(-45deg, transparent 47.5%, rgba(255, 255, 255, 0.8) 47.5%, rgba(255, 255, 255, 0.8) 52.5%, transparent 52.5%, transparent);
        z-index: 1;
        background-size: 2rem 1.9rem;
        animation: move 2s linear infinite;
        border-radius: 2rem .8rem .8rem 2rem;
        overflow: hidden; }

@keyframes move {
  0% {
    background-position: 0 0; }
  100% {
    background-position: 2rem 1.9rem; } }
  .quiz__tab {
    display: none; }
    .quiz__tab.active {
      display: block; }
  .quiz__title {
    font-family: Lora, serif;
    font-size: 2.2rem;
    line-height: 206%;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 3rem; }
    .quiz__title .bold {
      font-weight: 700; }
    .quiz__title .orange {
      color: #ff9b00; }
  .quiz__form {
    position: relative;
    flex-grow: 1; }
  .quiz__tab {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  .quiz__fieldset {
    padding-right: 5rem; }
    .quiz__fieldset_cards {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 3.5rem; }
  .quiz__field input {
    display: none; }
  .quiz__back {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 206%;
    color: #8F8F8F;
    position: absolute;
    top: calc(100% + 2.4rem);
    left: 0;
    cursor: pointer;
    transition: opacity .25s; }
    .quiz__back:disabled {
      cursor: not-allowed;
      opacity: .7; }

.quiz-card {
  background: #FFFFFF;
  box-shadow: 0 0.4rem 1.6rem rgba(0, 0, 0, 0.08);
  border-radius: 1.2rem;
  width: 22rem;
  display: flex;
  flex-direction: column;
  cursor: pointer; }
  .quiz-card__image {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center; }
    .quiz-card__image img {
      max-width: 100%; }
  .quiz-card__text {
    height: 4.35rem;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.25;
    color: #1F1F1F;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem; }

.quiz-panel {
  display: block;
  background: #FFFFFF;
  box-shadow: 0 0.4rem 1.6rem rgba(0, 0, 0, 0.08);
  border-radius: 1.2rem;
  padding: 1.5rem 2.2rem;
  cursor: pointer;
  margin-bottom: 1.2rem; }
  .quiz-panel:last-child {
    margin-bottom: 0; }
  .quiz-panel_centered {
    text-align: center; }
  .quiz-panel_with-image {
    display: flex;
    align-items: center; }
  .quiz-panel__image {
    margin-right: 3.25rem;
    width: 3.5rem; }
  .quiz-panel__text {
    font-size: 1.3rem;
    line-height: 206%;
    color: #1F1F1F; }

.quiz-success__form {
  position: relative;
  z-index: 1;
  margin-bottom: 7.5rem; }
  .quiz-success__form:after {
    content: '';
    width: 96.5%;
    height: 2rem;
    position: absolute;
    bottom: -1rem;
    left: 1.75%;
    background: #FFFFFF;
    box-shadow: 0 0.4rem 1.7rem rgba(0, 0, 0, 0.08);
    border-radius: .8rem;
    z-index: -1; }

.quiz-success__form-content {
  background: #FFFFFF;
  box-shadow: 0 0.4rem 1.7rem rgba(0, 0, 0, 0.08);
  border-radius: .8rem;
  padding: 3rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.quiz-success__input {
  width: 30rem;
  margin-bottom: 1rem; }

.quiz-success__button {
  width: 30rem; }

.quiz-success__error-message {
  text-align: center;
  margin-top: 1rem; }

.quiz-success__hint {
  font-size: 1rem;
  line-height: 206%;
  color: #AFAFAF;
  position: absolute;
  top: calc(100% + 1.4rem);
  left: 4.5rem; }

.quiz-success__process {
  display: flex;
  align-items: center; }
  .quiz-success__process-image {
    position: relative;
    width: 12rem;
    height: 12rem;
    margin-right: 2rem; }
    .quiz-success__process-image span {
      font-family: Lora, serif;
      font-weight: 700;
      font-size: 2.2rem;
      line-height: 206%;
      text-transform: uppercase;
      color: #000000;
      text-align: center;
      width: 100%;
      height: 100%;
      background-image: url("../img/button-texture.jpg");
      background-size: cover;
      background-position: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      position: relative;
      z-index: -1; }
    .quiz-success__process-image canvas {
      z-index: 1; }
    .quiz-success__process-image img {
      position: absolute;
      left: 50%;
      top: calc(50% + .4rem);
      transform: translate(-50%, -50%); }
    .quiz-success__process-image span {
      font-family: Lora, serif;
      font-weight: 700;
      font-size: 2.2rem;
      text-transform: uppercase;
      color: #000000;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
  .quiz-success__process-text {
    font-size: 1.3rem;
    line-height: 206%;
    color: #1F1F1F; }

@media screen and (max-width: 992px) {
  .quiz {
    padding-left: 0; }
    .quiz__sidebar {
      position: static;
      height: auto;
      width: auto;
      margin-bottom: 5rem; }
    .quiz__presents {
      display: flex;
      justify-content: space-between;
      flex-direction: column; }
    .quiz__present {
      display: flex;
      align-items: center; }
      .quiz__present-image {
        flex: 0 0 7.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0;
        margin-right: 2.5rem; }
      .quiz__present-1 .quiz__present-image, .quiz__present-2 .quiz__present-image, .quiz__present-3 .quiz__present-image {
        margin-left: 0; }
    .quiz__main {
      margin: 0 auto;
      height: auto; } }

@media screen and (max-width: 768px) {
  .quiz__main {
    width: 100%;
    max-width: 47.5rem; }
  .quiz__fieldset {
    padding-right: 0; } }

@media screen and (max-width: 576px) {
  .quiz__title {
    font-size: 1.8rem; }
  .quiz__fieldset_cards {
    grid-gap: 2rem; }
  .quiz__back {
    font-size: 1.4rem; }
  .quiz-card {
    width: 100%; }
    .quiz-card__image_question-mark img {
      width: 35%; }
  .quiz-success__title br {
    display: none; }
  .quiz-success__input {
    width: 100%;
    max-width: 30rem; }
  .quiz-success__button {
    width: 100%;
    max-width: 30rem; } }

@media screen and (max-width: 400px) {
  .quiz__fieldset_cards {
    grid-gap: 1rem; }
  .quiz-panel__image {
    margin-right: 1.5rem; }
  .quiz-success__form-content {
    padding: 3rem 1.5rem; } }

.price-category {
  width: 50rem; }
  .price-category__title {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 249.5%;
    color: #1F1F1F;
    text-align: center;
    margin-bottom: 4rem; }
  .price-category__table {
    width: 100%;
    font-size: 1.6rem;
    line-height: 249.5%;
    color: #1F1F1F;
    position: relative; }
    .price-category__table:after {
      content: '';
      width: 11.5rem;
      height: calc(100% + 2rem - 4.1rem - 1rem);
      background: #FF9B00;
      box-shadow: 0 0.4rem 1.5rem rgba(0, 0, 0, 0.13);
      border-radius: 1rem;
      position: absolute;
      right: .25rem;
      top: -2rem;
      z-index: -1; }
    .price-category__table th, .price-category__table td {
      text-align: center; }
      .price-category__table th:nth-child(2), .price-category__table td:nth-child(2) {
        width: 16.5rem;
        padding-left: .5rem;
        padding-right: 4.5rem; }
      .price-category__table th:last-child, .price-category__table td:last-child {
        color: #FFFFFF;
        padding: 0 1rem;
        width: 11.5rem; }
    .price-category__table th {
      font-weight: 700; }
    .price-category__table td:first-child {
      text-align: left; }
  .price-category__table-end-row td {
    padding-top: 1rem !important; }
    .price-category__table-end-row td:first-child {
      font-weight: 700;
      text-transform: uppercase; }
    .price-category__table-end-row td:nth-child(2) {
      text-decoration: line-through; }
    .price-category__table-end-row td:last-child {
      font-weight: 900;
      color: #FF9B00; }

@media screen and (max-width: 576px) {
  .price-category {
    max-width: 50rem;
    width: 100%; }
    .price-category__table {
      font-size: 1.2rem; }
      .price-category__table:after {
        content: '';
        width: 8.5rem;
        height: calc(100% + 1rem - 4.1rem - .5rem);
        background: #FF9B00;
        box-shadow: 0 0.4rem 1.5rem rgba(0, 0, 0, 0.13);
        border-radius: 1rem;
        position: absolute;
        right: .25rem;
        top: -1rem;
        z-index: -1; }
      .price-category__table th:nth-child(2), .price-category__table td:nth-child(2) {
        width: 10rem;
        padding-right: .5rem; }
      .price-category__table th:last-child, .price-category__table td:last-child {
        width: 8.5rem; } }

.quiz-block {
  padding-top: 6rem;
  padding-bottom: 13rem; }
  .quiz-block__title {
    margin-bottom: 10rem; }
    .quiz-block__title:before, .quiz-block__title:after {
      display: none; }
  .quiz-block__subtitle {
    margin-top: .5rem; }
  .quiz-block__quiz {
    display: flex;
    justify-content: center; }

.advantages-2 {
  position: relative; }
  .advantages-2__background-image {
    bottom: 13.6rem;
    height: 96.5rem; }
  .advantages-2__cloud {
    z-index: 1;
    bottom: -3rem;
    height: 44rem; }
  .advantages-2__title {
    margin-bottom: 3.5rem; }
  .advantages-2__items {
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 32rem;
    background-image: url("../img/circle.svg");
    background-position: center 5.5rem;
    background-repeat: no-repeat;
    background-size: 93rem 93rem; }
  .advantages-2__person {
    width: 47rem;
    margin-left: -3rem;
    position: relative;
    z-index: 1; }
    .advantages-2__person:before {
      content: '';
      width: 57rem;
      height: 23rem;
      background-image: url("../img/background-2.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      left: calc(50% + 1rem);
      top: 12rem;
      transform: translateX(-50%);
      z-index: -1; }
    .advantages-2__person .person__image {
      width: 35.5rem;
      margin-left: 15%;
      margin-bottom: -3rem; }

.advantage-2 {
  position: absolute;
  z-index: 2;
  top: 0;
  width: 19rem; }
  .advantage-2__image {
    width: 19rem;
    height: 19rem;
    filter: drop-shadow(0 0.4rem 1rem rgba(0, 0, 0, 0.08));
    border-radius: 1.2rem;
    margin-bottom: 1.6rem; }
  .advantage-2__text {
    font-size: 1.6rem;
    line-height: 206%;
    color: #1F1F1F;
    white-space: nowrap;
    padding: 0 1rem;
    display: flex;
    justify-content: center; }
  .advantage-2-1 {
    left: 0;
    top: 40rem; }
  .advantage-2-2 {
    left: 10%;
    top: 10%; }
  .advantage-2-3 {
    top: 0;
    left: calc(50% - 19rem - 3rem); }
  .advantage-2-4 {
    top: 0;
    right: calc(50% - 19rem - 3rem); }
  .advantage-2-5 {
    right: 10%;
    top: 10%; }
  .advantage-2-6 {
    right: 0;
    top: 40rem; }

@media screen and (max-width: 1240px) {
  .advantage-2-2 {
    left: 2.5%;
    top: 12.5%; }
  .advantage-2-5 {
    right: 2.5%;
    top: 12.5%; } }

@media screen and (max-width: 992px) {
  .advantages-2__items {
    padding-top: 0;
    flex-flow: row wrap;
    background: none; }
  .advantage-2 {
    position: static;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 2rem; } }

@media screen and (max-width: 576px) {
  .advantages-2__person {
    font-size: .8rem;
    width: 37rem; }
    .advantages-2__person .person__image {
      width: 20rem;
      margin-left: 9.5rem; }
    .advantages-2__person:before {
      width: 38rem;
      height: 15.33333rem; }
  .advantage-2 {
    width: 13rem; }
    .advantage-2__image {
      width: 13rem;
      height: 13rem; }
    .advantage-2__text {
      font-size: 1.2rem; } }

.portfolio {
  padding-top: 9.25rem;
  padding-bottom: 4.5rem;
  position: relative; }
  .portfolio__background-image {
    top: auto;
    bottom: 9rem;
    height: 78rem; }
  .portfolio__title {
    flex-direction: column;
    align-items: center;
    margin-right: -3rem;
    margin-bottom: 5rem; }
    .portfolio__title .subtitle {
      font-family: 'Gotham Pro', sans-serif;
      text-transform: none;
      font-weight: 400; }
  .portfolio__items {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    margin-bottom: 2.5rem; }
  .portfolio__items-more {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 206%;
    color: #FF9B00;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer; }
    .portfolio__items-more span {
      position: relative; }
      .portfolio__items-more span:after {
        content: '';
        height: .2rem;
        width: 100%;
        background-image: url("../img/dots.svg");
        background-position: .2rem 0;
        background-repeat: repeat-x;
        background-size: contain;
        position: absolute;
        top: calc(100% + .5rem);
        left: 0; }

.portfolio-item {
  width: calc(33.33% - 2.5*2rem/3);
  max-width: 40.5rem;
  margin-bottom: 4rem;
  display: none; }
  .portfolio-item.active {
    display: flex; }
  .portfolio-item__inner {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .portfolio-item__gallery {
    background: #FFFFFF;
    box-shadow: 0.6rem 0.6rem 2.1rem rgba(91, 91, 91, 0.27);
    border-radius: 1.4rem;
    padding: 2.5rem;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 2.5rem;
    width: 100%;
    height: 39.5rem; }
  .portfolio-item__selected-image {
    grid-column: 1/4;
    height: 25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; }
    .portfolio-item__selected-image img {
      max-width: 100%;
      max-height: 25rem; }
  .portfolio-item__image {
    cursor: pointer;
    width: 100%;
    max-height: 7.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; }
    .portfolio-item__image img {
      object-fit: cover;
      width: 100%;
      height: 100%; }
  .portfolio-item__characteristics {
    max-width: 35rem; }
  .portfolio-item__characteristic {
    font-size: 16px;
    line-height: 206%;
    color: #1F1F1F; }
    .portfolio-item__characteristic-name {
      font-weight: 700; }

@media screen and (max-width: 992px) {
  .portfolio__title {
    margin-right: -5rem; }
  .portfolio__items {
    margin-bottom: 2.5rem; }
  .portfolio-item {
    width: calc(50% - 2.5*1rem/2);
    margin-bottom: 4rem; }
    .portfolio-item__gallery {
      grid-gap: 1rem;
      height: 33.5rem; }
    .portfolio-item__selected-image {
      max-height: 20rem; }
      .portfolio-item__selected-image img {
        max-height: 20rem; } }

@media screen and (max-width: 768px) {
  .portfolio__title {
    margin-right: 0; } }

@media screen and (max-width: 576px) {
  .portfolio__items {
    justify-content: center; }
  .portfolio__items-more {
    font-size: 1.4rem; }
  .portfolio-item {
    width: 100%; }
    .portfolio-item__characteristic {
      font-size: 1.4rem; } }

.calculate {
  padding-top: 4rem;
  padding-bottom: 6rem; }
  .calculate__title {
    font-size: 1.6rem;
    line-height: 206%;
    color: #1F1F1F;
    margin-bottom: 1.5rem;
    text-align: center; }
  .calculate__form {
    margin: 0 auto; }

@media screen and (max-width: 576px) {
  .calculate__title {
    font-size: 1.4rem; } }

.reviews {
  padding-top: 6rem;
  padding-bottom: 4rem; }
  .reviews__title {
    margin-bottom: 5rem; }
  .reviews__carousel {
    width: 93rem;
    margin: 0 auto; }
    .reviews__carousel .owl-stage {
      display: flex; }
    .reviews__carousel .owl-stage-outer {
      overflow: visible; }
    .reviews__carousel .owl-item {
      opacity: 0;
      transition: opacity .25s; }
      .reviews__carousel .owl-item.active {
        opacity: 1; }
    .reviews__carousel-item {
      height: 100%; }
    .reviews__carousel .owl-prev, .reviews__carousel .owl-next {
      width: 6.7rem;
      height: 6.7rem;
      background: #E1E1E1 !important;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 21rem; }
    .reviews__carousel .owl-prev {
      right: calc(100% + 6rem); }
      .reviews__carousel .owl-prev img {
        transform: scale(-1, 1); }
    .reviews__carousel .owl-next {
      left: calc(100% + 6rem); }

.review {
  width: 27rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column; }
  .review__phone {
    width: 26rem;
    height: 53rem;
    background-image: url("../img/phone.jpg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    margin-bottom: 6.5rem;
    margin-left: 1rem; }
  .review__image {
    position: absolute;
    top: 6.5rem;
    left: .9rem;
    width: 24.25rem !important;
    height: 39.5rem; }
  .review__quote {
    flex-grow: 1;
    margin-left: 2rem;
    position: relative;
    z-index: 1; }
    .review__quote-content {
      background: #FFFFFF;
      box-shadow: 0 0.4rem 1.7rem rgba(0, 0, 0, 0.08);
      border-radius: .8rem;
      padding: 3.5rem 1.5rem 3.5rem 4rem;
      height: 100%; }
    .review__quote-text {
      font-size: 1.3rem;
      line-height: 206%;
      color: #1F1F1F; }
    .review__quote:before {
      content: '“';
      font-family: Lora, serif;
      font-weight: 700;
      font-size: 20rem;
      text-transform: uppercase;
      color: #FF9B00;
      line-height: 0;
      position: absolute;
      left: -.3em;
      top: .25em; }
    .review__quote:after {
      content: '';
      width: 90%;
      height: 2.2rem;
      position: absolute;
      left: 5%;
      bottom: -1.1rem;
      background: #FFFFFF;
      box-shadow: 0 0.4rem 1.7rem rgba(0, 0, 0, 0.08);
      border-radius: .8rem;
      z-index: -1; }

@media screen and (max-width: 1240px) {
  .reviews__carousel {
    width: 60rem; } }

@media screen and (max-width: 992px) {
  .reviews__title {
    text-align: center; }
  .reviews__carousel .owl-prev {
    right: calc(100% - 1rem); }
  .reviews__carousel .owl-next {
    left: calc(100% - 1rem); } }

@media screen and (max-width: 768px) {
  .reviews__carousel {
    width: 100%; }
    .reviews__carousel .owl-prev {
      right: calc(50% + 13.5rem + 4rem); }
    .reviews__carousel .owl-next {
      left: calc(50% + 13.5rem + 4rem); } }

@media screen and (max-width: 576px) {
  .reviews__carousel .owl-prev, .reviews__carousel .owl-next {
    width: 4rem;
    height: 4rem; }
    .reviews__carousel .owl-prev img, .reviews__carousel .owl-next img {
      width: 30%; }
  .reviews__carousel .owl-prev {
    right: calc(50% + 12rem + 1rem); }
  .reviews__carousel .owl-next {
    left: calc(50% + 12rem + 1rem); }
  .review {
    width: 24rem; }
    .review__phone {
      width: 24rem;
      height: 49rem;
      margin-left: 0; }
    .review__image {
      width: 22.5rem !important;
      height: 36.25rem;
      top: 6rem;
      left: .8rem; } }

@media screen and (max-width: 360px) {
  .reviews__title {
    margin-bottom: 6rem; }
  .reviews__carousel .owl-prev, .reviews__carousel .owl-next {
    top: -5rem; }
  .reviews__carousel .owl-prev {
    right: calc(50% + 1rem); }
  .reviews__carousel .owl-next {
    left: calc(50% + 1rem); }
  .review__phone {
    margin-bottom: 2.5rem; } }

.consult {
  position: relative; }
  .consult > .page-container {
    position: relative;
    padding-top: 10rem;
    padding-bottom: 4rem; }
  .consult__background-image {
    height: 96.5rem;
    bottom: 22rem; }
  .consult__cloud {
    bottom: 0;
    z-index: 1;
    height: 65rem; }
  .consult__main {
    margin-bottom: 4rem; }
  .consult__content {
    max-width: 53rem; }
  .consult__title {
    margin-bottom: 1rem; }
  .consult__subtitle {
    margin-bottom: 1.5rem; }
  .consult__steps {
    position: relative;
    z-index: 1; }
    .consult__steps-title {
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 206%;
      color: #686868;
      margin-bottom: 3.5rem; }
  .consult__form {
    margin: 0 auto; }
  .consult__consultant {
    position: absolute;
    top: 0;
    right: -5rem;
    width: 59rem; }

.consult-step {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0 0.4rem 1.7rem rgba(0, 0, 0, 0.08);
  border-radius: .8rem;
  padding: 2.5rem 3rem;
  font-size: 1.4rem;
  line-height: 206%;
  color: #1F1F1F;
  margin-bottom: 1.3rem; }
  .consult-step:last-child {
    margin-bottom: 0; }
  .consult-step__number {
    width: 3.8rem;
    height: 3.8rem;
    font-weight: 900;
    font-size: 1.6rem;
    line-height: 206%;
    color: #FFFFFF;
    background: #FF9B00;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -1.4rem;
    left: -1.7rem; }
  .consult-step__title {
    font-weight: 700; }
  .consult-step__br-mobile {
    display: none; }

@media screen and (max-width: 1240px) {
  .consult__main {
    position: relative; }
  .consult__consultant {
    width: 47rem;
    top: auto;
    bottom: -6.25rem;
    right: -10rem; }
    .consult__consultant .person__image {
      margin-left: 4rem; } }

@media screen and (max-width: 992px) {
  .consult__cloud {
    display: none; }
  .consult__main {
    position: static; }
  .consult__consultant {
    width: 38.5rem;
    font-size: .8rem;
    z-index: 2;
    right: -12.5rem; }
    .consult__consultant .person__image {
      width: 30rem; }
  .consult__form {
    margin: 0; } }

@media screen and (max-width: 768px) {
  .consult__main {
    margin-bottom: 2.5rem; }
  .consult__content {
    margin: 0 auto;
    margin-bottom: 2.5rem; }
  .consult__consultant {
    position: static;
    margin: 0 auto; }
  .consult__form {
    margin: 0 auto; } }

@media screen and (max-width: 576px) {
  .consult__main {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .consult__steps-title {
    font-size: 1.4rem; }
  .consult__consultant {
    margin: 0; }
  .consult-step {
    padding-top: 3rem;
    font-size: 1.2rem; }
    .consult-step__number {
      left: -.75rem; }
    .consult-step__br-mobile {
      display: block; } }

.certificates {
  padding-top: 7.5rem;
  padding-bottom: 5rem; }
  .certificates > .page-container {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .certificates__content {
    width: 45rem; }
  .certificates__title {
    margin-bottom: 1.4rem; }
  .certificates__items {
    background: #FFFFFF;
    box-shadow: 0.6rem 0.6rem 2.1rem rgba(91, 91, 91, 0.27);
    border-radius: 1.4rem;
    padding: 2.7rem 4rem 1.3rem;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    justify-content: space-between;
    max-width: 58rem; }

.certificate {
  margin-bottom: 1.4rem; }
  .certificate__image {
    width: 100%; }
  .certificate-1 {
    width: 32%; }
  .certificate-2 {
    width: 32%; }
  .certificate-3 {
    width: 32%; }
  .certificate-4 {
    width: 32%; }
  .certificate-5 {
    width: 32%; }
  .certificate-6 {
    width: 32%; }
  .certificate-7 {
    width: 64%; }

@media screen and (max-width: 1240px) {
  .certificates__items {
    max-width: 40rem; }
  .certificate-1 {
    width: 49%; }
  .certificate-2 {
    width: 49%; }
  .certificate-3 {
    width: 49%; }
  .certificate-4 {
    width: 49%; }
  .certificate-5 {
    width: 49%; }
  .certificate-6 {
    width: 49%; }
  .certificate-7 {
    width: 100%; } }

@media screen and (max-width: 992px) {
  .certificates > .page-container {
    flex-direction: column; }
  .certificates__content {
    margin-bottom: 5rem; }
  .certificates__items {
    max-width: 53rem; }
  .certificate-1 {
    width: 32%; }
  .certificate-2 {
    width: 32%; }
  .certificate-3 {
    width: 32%; }
  .certificate-4 {
    width: 32%; }
  .certificate-5 {
    width: 32%; }
  .certificate-6 {
    width: 32%; }
  .certificate-7 {
    width: 64%; } }

@media screen and (max-width: 576px) {
  .certificates__content {
    width: auto; }
  .certificates__items {
    max-width: 35rem;
    padding: 1.75rem 1.5rem .3rem; }
  .certificate-1 {
    width: 49%; }
  .certificate-2 {
    width: 49%; }
  .certificate-3 {
    width: 49%; }
  .certificate-4 {
    width: 49%; }
  .certificate-5 {
    width: 49%; }
  .certificate-6 {
    width: 49%; }
  .certificate-7 {
    width: 100%; } }

.brands {
  padding-top: 6rem;
  padding-bottom: 5rem;
  position: relative; }
  .brands__background-image {
    height: 96.5rem;
    top: auto;
    bottom: 32rem; }
  .brands__title {
    margin-bottom: 6rem; }
  .brands__list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4.5rem; }
  .brands__list-item {
    filter: drop-shadow(0 0.4rem 1.7rem rgba(0, 0, 0, 0.08));
    width: 13rem;
    height: 13.8rem;
    cursor: pointer;
    background: #fff;
    border-radius: .4rem;
    padding: .75rem;
    margin-right: 1.3rem; }
    .brands__list-item img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      border-radius: 4px; }
    .brands__list-item:last-child {
      margin-right: 0; }
  .brands__work {
    width: 78rem;
    margin: 0 auto; }
  .brands__carousel {
    margin-bottom: 2rem; }
    .brands__carousel-item {
      width: 78rem;
      height: 50rem;
      display: flex;
      align-items: center;
      justify-content: center; }
      .brands__carousel-item img {
        border-radius: 1.1rem;
        width: auto !important;
        max-width: 100%;
        max-height: 100%; }
    .brands__carousel-dots {
      position: absolute;
      left: 0;
      top: 0;
      transition: left .25s, right .25s;
      width: 100%;
      height: 100%;
      display: flex; }
      .brands__carousel-dots-wrapper {
        width: 100%;
        height: 11.5rem;
        margin-bottom: 4rem;
        overflow: hidden;
        position: relative; }
    .brands__carousel-dot {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: .3rem;
      overflow: hidden;
      margin: 0 1.2rem;
      flex: 0 0 calc(25% - 1.2*2rem);
      height: 100%; }
      .brands__carousel-dot img {
        min-width: 100%;
        min-height: 100%; }
    .brands__carousel .owl-prev, .brands__carousel .owl-next {
      width: 4.6rem;
      height: 4.6rem;
      background: #FFFFFF !important;
      box-shadow: 0 0.4rem 0.9rem rgba(0, 0, 0, 0.08);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%; }
    .brands__carousel .owl-prev {
      right: calc(100% + 8.5rem); }
      .brands__carousel .owl-prev img {
        transform: scale(-1, 1); }
    .brands__carousel .owl-next {
      left: calc(100% + 8.5rem); }
  .brands__work-description {
    font-size: 16px;
    line-height: 206%;
    color: #1F1F1F; }
    .brands__work-description .bold {
      font-weight: 700; }

@media screen and (max-width: 1240px) {
  .brands__carousel .owl-prev {
    right: calc(100% + 3rem); }
    .brands__carousel .owl-prev img {
      transform: scale(-1, 1); }
  .brands__carousel .owl-next {
    left: calc(100% + 3rem); } }

@media screen and (max-width: 992px) {
  .brands__list {
    flex-flow: row wrap; }
    .brands__list-item {
      margin-left: .5rem;
      margin-right: .5rem;
      margin-bottom: 1rem; }
      .brands__list-item:last-child {
        margin-right: .5rem; }
  .brands__work {
    width: 100%; }
  .brands__work-description {
    padding: 0 5rem; }
  .brands__carousel {
    padding: 0 5rem; }
    .brands__carousel-item {
      width: 100%; }
    .brands__carousel .owl-prev {
      right: calc(100% - 4rem); }
    .brands__carousel .owl-next {
      left: calc(100% - 4rem); } }

@media screen and (max-width: 768px) {
  .brands__carousel-dots-wrapper {
    height: 9rem; } }

@media screen and (max-width: 576px) {
  .brands__work {
    padding-top: 6rem; }
  .brands__work-description {
    padding: 0;
    font-size: 1.4rem; }
  .brands__carousel {
    padding: 0; }
    .brands__carousel-item {
      height: 40rem; }
    .brands__carousel-dots {
      padding: 0;
      margin-bottom: 2rem;
      height: 7rem; }
    .brands__carousel-dot {
      margin: 0 .6rem;
      flex: 0 0 calc(25% - .6*2rem); }
    .brands__carousel .owl-prev, .brands__carousel .owl-next {
      top: -5.6rem; }
    .brands__carousel .owl-prev {
      right: calc(50% + 1rem); }
    .brands__carousel .owl-next {
      left: calc(50% + 1rem); } }

.guarantee {
  padding-top: 6rem;
  padding-bottom: 6rem; }
  .guarantee__title {
    margin-bottom: 9rem; }
  .guarantee__content {
    display: flex; }
  .guarantee__list {
    flex: 0 0 50%;
    margin-right: 2.4rem; }
  .guarantee__image {
    flex: 0 0 50%; }

.guarantee-list-item {
  background: #FFFFFF;
  box-shadow: 0 0.4rem 1.7rem rgba(0, 0, 0, 0.08);
  border-radius: .8rem;
  padding: 2.25rem 5.5rem 2rem 3rem;
  margin-bottom: 2.2rem;
  position: relative; }
  .guarantee-list-item:last-child {
    margin-bottom: 0; }
  .guarantee-list-item__number {
    width: 3.8rem;
    height: 3.8rem;
    background: #FF9B00;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    font-size: 1.6rem;
    line-height: 206%;
    color: #FFFFFF;
    position: absolute;
    left: -1.7rem;
    top: -1.4rem; }
  .guarantee-list-item__title {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 206%;
    color: #1F1F1F;
    margin-bottom: .5rem; }
  .guarantee-list-item__text {
    font-size: 1.2rem;
    line-height: 206%;
    color: #1F1F1F; }

@media screen and (max-width: 1240px) {
  .guarantee__content {
    display: flex;
    flex-direction: column; }
  .guarantee__list {
    margin-bottom: 5rem;
    margin-right: 0; }
  .guarantee__image {
    text-align: center; } }

@media screen and (max-width: 992px) {
  .guarantee__title {
    text-align: center; }
    .guarantee__title br {
      display: none; } }

@media screen and (max-width: 576px) {
  .guarantee-list-item {
    padding-top: 2.5rem; }
    .guarantee-list-item__number {
      left: -.75rem; } }

.steps {
  padding-top: 7rem;
  padding-bottom: 8rem; }
  .steps > .page-container {
    width: 100%;
    height: 66.5rem;
    position: relative;
    transition: height .25s; }

.step {
  display: none;
  position: absolute;
  padding: 0 2rem;
  left: 0;
  top: 0;
  width: 100%; }
  .step__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%; }
  .step__content {
    flex-grow: 1; }
  .step.active {
    display: block; }
  .step__text-block {
    padding: 3.8rem 6rem;
    background: #FFFFFF;
    box-shadow: 0 0.4rem 1.7rem rgba(0, 0, 0, 0.08);
    border-radius: .84rem; }
  .step__list-title {
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 206%;
    color: #1F1F1F;
    margin-bottom: 1.5em;
    padding-lefT: 4.75rem; }
  .step__list-item {
    font-size: 1.2rem;
    line-height: 206%;
    color: #1F1F1F;
    padding-left: 4.75rem;
    margin-bottom: 1.5em;
    position: relative; }
    .step__list-item:last-child {
      margin-bottom: 0; }
    .step__list-item:before {
      content: '';
      width: 2.75rem;
      height: .4rem;
      background: #FF9B00;
      position: absolute;
      top: calc(50% - .2rem);
      left: 0; }
  .step__counter {
    margin-top: 4.3rem; }
    .step__counter-mobile {
      display: none; }
    .step__counter-main {
      display: flex;
      align-items: center; }
    .step__counter-button .button__layer_top {
      background-image: url(../img/button-texture-gray.jpg); }
    .step__counter-button .button__layer_bottom {
      background: #8F8F8F; }
    .step__counter-text {
      font-family: 'Lora', serif;
      font-weight: 700;
      font-size: 4rem;
      line-height: 206%;
      color: #737373;
      margin-left: 4.5rem; }
  .step__previous {
    margin-top: 2rem;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 206%;
    color: #8F8F8F; }

.step-1__title {
  margin-bottom: 8rem; }

.step-1__content {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }

.step-1__text-block {
  max-width: 51rem; }

.step-1__image {
  width: 32rem;
  z-index: -1;
  position: absolute;
  bottom: 0;
  right: 36rem; }

.step-2__content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative; }

.step-2__title {
  margin-bottom: 8.5rem; }

.step-2__left {
  width: 51rem; }

.step-2__text-block {
  max-width: 51rem; }

.step-2__image {
  height: 100%;
  position: absolute;
  right: 15%;
  bottom: 0; }
  .step-2__image img {
    max-height: 100%; }

.step-3__title {
  margin-bottom: 7rem; }

.step-3__content {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.step-3__list {
  max-width: 51rem; }

.step-3__list-item {
  background: #FFFFFF;
  box-shadow: 0 0.4rem 1.7rem rgba(0, 0, 0, 0.08);
  border-radius: .8rem;
  margin-bottom: 2.8rem;
  display: block;
  padding: 2rem 5rem 2rem 9.75rem; }
  .step-3__list-item:before {
    left: 5rem; }

.step-3__gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 3rem;
  grid-column-gap: 2rem;
  max-width: 50rem; }

.step-3__gallery-image {
  width: 100%; }
  .step-3__gallery-image:first-child {
    grid-column: 1/4; }

.step-4__title {
  margin-bottom: 8.5rem; }

.step-4__content {
  display: flex;
  justify-content: space-between; }

.step-4__text-block {
  max-width: 51rem; }

.step-4__image {
  max-width: 50rem; }

.step-5__title {
  margin-bottom: 4rem; }

.step-5__content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }

.step-5__text-block {
  margin-bottom: 3.5rem;
  max-width: 51rem; }

.step-5__left {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

.step-5__form {
  display: flex;
  align-items: start;
  position: relative;
  margin-bottom: 6rem; }
  .step-5__form-input {
    margin-right: 2.5rem; }
  .step-5__form-button {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .step-5__form-hint {
    font-size: 1rem;
    line-height: 206%;
    color: #AFAFAF;
    max-width: 20.5rem;
    position: absolute;
    top: calc(100% + 3rem);
    right: 2rem; }

.step-5__error-message {
  text-align: center;
  margin-top: 1rem; }

.step-5__image-mobile {
  display: none; }

.step-5__image img {
  border-radius: 4.5rem;
  width: 50rem; }

@media screen and (max-width: 1240px) {
  .step-1__image {
    display: none; }
  .step-2__image {
    right: 0; }
  .step-3__content {
    align-items: flex-start; }
  .step-3__left {
    margin-right: 2.5rem; }
  .step-4__content {
    align-items: flex-start; }
  .step-4__left {
    margin-right: 2.5rem; }
  .step-5__left {
    width: 61rem;
    margin-right: 2.5rem; }
  .step-5__image {
    width: auto;
    margin-left: -10rem; } }

@media screen and (max-width: 992px) {
  .step__title {
    padding: 0;
    text-align: center;
    margin-bottom: 5rem !important; }
    .step__title:after, .step__title:before {
      display: none; }
    .step__title br {
      display: none; }
  .step__text-block {
    padding: 3.8rem 3rem;
    margin-bottom: 4rem; }
  .step__inner {
    height: 100%; }
  .step__counter {
    display: none; }
    .step__counter-mobile {
      display: block; }
  .step-1__content {
    flex-direction: column;
    align-items: center; }
  .step-2__content {
    flex-direction: column;
    align-items: center; }
  .step-2__image {
    position: static;
    height: 48.5rem; }
    .step-2__image img {
      height: 100%; }
  .step-3__content {
    flex-direction: column;
    align-items: center; }
  .step-3__left {
    margin-right: 0; }
  .step-3__list {
    margin-bottom: 4rem; }
  .step-3__gallery {
    max-width: 45rem; }
  .step-4__content {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; }
  .step-4__left {
    margin-right: 0; }
  .step-5__content {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center; }
  .step-5__left {
    margin-right: 0;
    align-items: center; }
  .step-5__image {
    margin-left: 0;
    margin-bottom: 5rem;
    display: none; }
    .step-5__image-mobile {
      display: block; }
  .step-5__previous {
    align-self: flex-start; } }

@media screen and (max-width: 768px) {
  .step-5__left {
    width: auto; }
  .step-5__form {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0; }
    .step-5__form-input {
      margin-right: 0;
      margin-bottom: 1.5rem; }
    .step-5__form-button {
      margin-bottom: 1.5rem; }
    .step-5__form-hint {
      position: static; }
  .step-5__image {
    max-width: 51rem; }
  .step-5__previous {
    align-self: center; } }

@media screen and (max-width: 576px) {
  .step {
    padding: 0 1rem;
    height: auto; }
    .step__title {
      margin-bottom: 3rem !important; }
    .step__counter-text {
      font-size: 3rem;
      margin-left: 1rem; }
  .step-2__left {
    width: auto; }
  .step-3__gallery {
    grid-gap: 1rem; }
  .step-3__list-item {
    padding: 2rem 2rem 2rem 6.75rem; }
    .step-3__list-item:before {
      left: 2rem; }
  .step-5__left {
    flex-direction: column;
    align-items: center; } }

.faq {
  padding-top: 6rem;
  padding-bottom: 6rem; }
  .faq__title {
    margin-bottom: 9rem; }

.faq-accordion {
  max-width: 54.5rem;
  margin: 0 auto; }
  .faq-accordion .accordion-item.active .accordion-item__icon {
    transform: rotate(180deg); }
  .faq-accordion .accordion-item__header {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 179.19%;
    color: #404040;
    padding: 1.1rem 5rem 1.1rem 0;
    border-top: .2rem solid #E1E1E1;
    position: relative;
    cursor: pointer; }
  .faq-accordion .accordion-item__icon {
    position: absolute;
    right: 0;
    top: 1.1em; }
  .faq-accordion .accordion-item__panel {
    font-size: 1.2rem;
    line-height: 179.19%;
    color: #404040;
    padding-top: .3rem;
    padding-bottom: 3rem;
    padding-right: 6.8rem;
    display: none; }

@media screen and (max-width: 1240px) {
  .faq__title {
    text-align: center; } }

@media screen and (max-width: 576px) {
  .faq-accordion .accordion-item__header {
    font-size: 1.4rem; }
  .faq-accordion .accordion-item__panel {
    padding-right: 3rem; } }

.request-2 {
  padding-top: 6rem;
  padding-bottom: 10.5rem;
  position: relative; }
  .request-2__title {
    margin-bottom: 4.5rem; }
  .request-2__content {
    display: flex;
    align-items: flex-start;
    justify-content: center; }
  .request-2__background {
    top: -15rem; }

.request-2-director {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 15rem; }
  .request-2-director__image {
    width: 25rem;
    display: block;
    margin: 0 auto; }
  .request-2-director__panel-wrapper {
    position: relative;
    z-index: 1;
    width: 52rem; }
    .request-2-director__panel-wrapper:after {
      content: '';
      width: 94%;
      height: 2.4rem;
      position: absolute;
      bottom: -1.2rem;
      left: 3%;
      background: #FFFFFF;
      box-shadow: 0 0.4rem 1.7rem rgba(0, 0, 0, 0.08);
      border-radius: .8rem;
      z-index: -1; }
  .request-2-director__panel {
    background: #FFFFFF;
    box-shadow: 0 0.4rem 1.7rem rgba(0, 0, 0, 0.08);
    border-radius: .8rem;
    padding: 3rem 5rem 3rem 7rem;
    text-align: right; }
    .request-2-director__panel:before {
      content: '“';
      font-family: 'Lora', serif;
      font-weight: 700;
      font-size: 20rem;
      line-height: 206%;
      text-transform: uppercase;
      color: #FF9B00;
      line-height: 0;
      position: absolute;
      left: -4rem;
      top: 5rem; }
  .request-2-director__status {
    font-size: 1.6rem;
    line-height: 206%;
    color: #1F1F1F; }
  .request-2-director__name {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 206%;
    color: #1F1F1F; }

@media screen and (max-width: 1240px) {
  .request-2-director {
    margin-right: 2.5rem; } }

@media screen and (max-width: 992px) {
  .request-2__content {
    flex-direction: column-reverse;
    align-items: center; }
  .request-2__form {
    margin-bottom: 7.5rem; }
  .request-2-director {
    margin-right: 0; } }

@media screen and (max-width: 576px) {
  .request-2-director__image {
    max-width: 25rem; }
  .request-2-director__panel-wrapper {
    width: auto;
    max-width: 40rem; }
  .request-2-director__panel {
    padding: 2rem 2rem 2rem 3rem; }
    .request-2-director__panel:before {
      font-size: 12rem;
      top: 1rem;
      left: 1rem; }
  .request-2-director__status {
    font-size: 1.4rem; }
  .request-2-director__name {
    font-size: 1.4rem; } }

.map {
  position: relative;
  padding-top: 8.5rem;
  padding-bottom: 3.5rem; }
  .map__iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("../img/map.jpg");
    background-size: auto 100%;
    background-position: center;
    background-repeat: repeat-x;
    z-index: 1; }
    .map__iframe iframe {
      width: 100%;
      height: 100%; }
  .map__card {
    background: #FFFFFF;
    box-shadow: 0 0.4rem 1.7rem rgba(0, 0, 0, 0.08);
    border-radius: .8rem;
    padding: 3.5rem 4.5rem 2rem;
    width: 51rem;
    position: relative;
    z-index: 2; }
  .map__general {
    margin-bottom: 1rem; }
  .map__title {
    margin-bottom: .4rem; }
  .map__general-subtitle {
    margin-bottom: .4rem; }
  .map__subtitle {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 206%;
    color: #1F1F1F; }
  .map__address {
    font-size: 1.6rem;
    line-height: 206%;
    color: #6C6C6C; }
    .map__address a {
      color: #6C6C6C; }
  .map__text {
    font-size: 1.6rem;
    line-height: 206%;
    color: #1F1F1F;
    margin-bottom: 1.2rem; }

@media screen and (max-width: 576px) {
  .map {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 0; }
    .map__iframe {
      position: static;
      height: 50rem;
      background-position: calc(100% + 5rem) 0; }
    .map__content {
      margin-bottom: 5rem; }
    .map__card {
      width: auto;
      max-width: 50rem;
      padding: 2rem 2rem 2rem; }
    .map__subtitle {
      font-size: 1.4rem; }
    .map__address {
      font-size: 1.4rem; }
    .map__text {
      font-size: 1.4rem; } }

@media screen and (max-width: 500px) {
  .map__iframe {
    height: 30rem;
    background-size: auto 125%;
    background-position: calc(100% + 7.5rem) center; } }

.check-list {
  padding-top: 10.5rem;
  padding-bottom: 20.5rem;
  background-image: url("../img/background-1.jpg");
  background-size: auto 100%;
  background-position: center;
  background-repeat: repeat-x;
  position: relative; }
  .check-list > .page-container {
    position: relative;
    z-index: 2; }
  .check-list__title {
    margin-bottom: 12rem; }
  .check-list__image {
    max-width: none;
    width: 124rem;
    height: 87rem;
    position: absolute;
    top: 0rem;
    right: -30rem; }

@media screen and (max-width: 1240px) {
  .check-list__image {
    top: 0rem;
    right: -50rem; } }

@media screen and (max-width: 992px) {
  .check-list__image {
    top: 15rem;
    right: -35rem;
    width: 82.66667rem;
    height: 58rem; } }

@media screen and (max-width: 768px) {
  .check-list > .page-container {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .check-list__title {
    margin-bottom: 0;
    order: 1; }
    .check-list__title br {
      display: none; }
  .check-list__image {
    position: static;
    width: auto;
    height: auto;
    max-width: 100%;
    order: 2; }
  .check-list__form {
    order: 3; } }

@media screen and (max-width: 576px) {
  .check-list__title {
    text-align: center; } }

.footer {
  background: #FFFFFF;
  box-shadow: 0 -0.4rem 1.7rem rgba(155, 155, 155, 0.25);
  /*
        ============================================================
        General
        ============================================================
    */
  /*
        ============================================================
        Body
        ============================================================
    */
  /*
        ============================================================
        Bottom
        ============================================================
    */ }
  .footer > .page-container {
    padding: 5rem 2rem; }
  .footer__title {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 206%;
    color: #000000;
    text-transform: uppercase; }
  .footer__text {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 206%;
    color: #606060; }
  .footer__body {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 3.5rem; }
  .footer__left {
    display: flex;
    align-items: flex-start;
    margin-right: 10rem; }
  .footer__right {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-grow: 1; }
  .footer__logo {
    margin-right: 4rem;
    max-width: 13rem; }
  .footer__soc-links-title {
    margin-bottom: .5em; }
  .footer__soc-links__content {
    display: flex;
    align-items: center; }
  .footer__soc-link {
    display: block;
    width: 3.3rem;
    margin-right: 1.7rem; }
    .footer__soc-link:last-child {
      margin-right: 0; }
  .footer__contacts {
    display: flex;
    flex-direction: column; }
  .footer__phone {
    margin-bottom: .5rem; }
  .footer__copyright-title {
    font-size: 1rem;
    line-height: 206%;
    color: #272727;
    margin-bottom: .5rem; }
  .footer__copyright-text {
    font-size: 1rem;
    line-height: 206%;
    color: #AFAFAF;
    max-width: 80rem; }

@media screen and (max-width: 1240px) {
  .footer__body {
    flex-direction: column; }
  .footer__left {
    margin-right: 0;
    margin-bottom: 2.5rem; }
  .footer__right {
    width: 100%; } }

@media screen and (max-width: 576px) {
  .footer__body {
    align-items: center; }
  .footer__right {
    width: auto;
    align-items: center;
    flex-direction: column;
    text-align: center; }
  .footer__tagline {
    font-size: 1.2rem;
    max-width: 21rem; }
    .footer__tagline br {
      display: none; }
  .footer__soc-links {
    margin-bottom: 2.5rem; }
    .footer__soc-links__content {
      justify-content: center; }
  .footer__requisites {
    margin-bottom: 2.5rem; } }

@media screen and (max-width: 400px) {
  .footer__logo {
    margin-right: 1rem;
    flex: 0 0 11rem; } }

.intro {
  padding-top: 4rem;
  padding-bottom: 3rem; }
  .intro > .page-container {
    display: flex;
    justify-content: center;
    align-items: center; }
  .intro__text-block {
    max-width: 53rem;
    background: #FFFFFF;
    box-shadow: 0 0.4rem 1.7rem rgba(0, 0, 0, 0.08);
    border-radius: .8rem;
    padding: 5rem 5.5rem;
    margin-right: 2.75rem; }
  .intro__title {
    margin-bottom: 3.5rem; }
    .intro__title .thin {
      font-weight: 400; }
  .intro__list-item {
    padding-left: 8rem;
    font-size: 1.6rem;
    line-height: 206%;
    color: #000000;
    margin-bottom: 1.25rem;
    position: relative; }
    .intro__list-item:last-child {
      margin-bottom: 0; }
    .intro__list-item a {
      font-weight: 700;
      color: #FF9B00; }
    .intro__list-item:before {
      content: '';
      width: 5.2rem;
      height: .2rem;
      background: #FBBF62;
      position: absolute;
      left: 0;
      top: 1em; }
  .intro__person {
    width: 47rem; }
    .intro__person .person__image {
      width: 28rem;
      position: relative;
      left: 50%;
      transform: translateX(-50%); }

@media screen and (max-width: 992px) {
  .intro {
    padding-bottom: 8rem; }
    .intro > .page-container {
      flex-direction: column-reverse; }
    .intro__person {
      margin-bottom: 5rem; } }

@media screen and (max-width: 576px) {
  .intro__person {
    font-size: .8rem;
    width: 38rem; }
  .intro__text-block {
    padding: 2.5rem 2.5rem;
    margin: 0 auto; }
  .intro__title {
    margin-bottom: 2.5rem; }
  .intro__list-item {
    font-size: 1.2rem;
    padding-left: 4.2rem; }
    .intro__list-item:before {
      width: 3rem; } }

.description {
  position: relative; }
  .description__background-image {
    height: 96.5rem;
    top: 25rem; }
  .description__top {
    max-width: 90rem;
    margin: 0 auto;
    margin-bottom: 5.5rem;
    padding-left: 1.5rem; }
  .description__title {
    margin-bottom: 1.5rem; }
    .description__title .thin {
      font-weight: 400; }
  .description__list {
    font-size: 1.6rem;
    line-height: 286.5%;
    color: #1F1F1F; }
    .description__list-item {
      padding-left: 8.9rem;
      position: relative; }
      .description__list-item:before {
        content: '';
        width: 5.2rem;
        height: .2rem;
        background: #FBBF62;
        position: absolute;
        left: 0;
        top: 1.4em; }
  .description__image {
    background: #FFFFFF;
    box-shadow: 0.6rem 0.6rem 2.1rem rgba(91, 91, 91, 0.27);
    border-radius: 2.1rem;
    max-width: 88rem;
    margin: 0 auto;
    margin-bottom: 9rem;
    padding: 2rem; }
    .description__image img {
      width: 100%; }
  .description__price-categories {
    display: flex;
    flex-flow: row wrap;
    justify-content: center; }
  .description__price-category {
    margin-right: 5rem;
    margin-left: 5rem;
    margin-bottom: 7.5rem; }

.description-card {
  max-width: 90rem;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  margin-bottom: 8.5rem; }
  .description-card:before {
    content: '';
    width: 96.5%;
    height: 3.2rem;
    position: absolute;
    left: 1.75%;
    bottom: -1.6rem;
    background: #FFFFFF;
    box-shadow: 0 0.4rem 1.7rem rgba(0, 0, 0, 0.08);
    border-radius: .8rem;
    z-index: -1; }
  .description-card__content {
    background: #FFFFFF;
    box-shadow: 0 0.4rem 1.7rem rgba(0, 0, 0, 0.08);
    border-radius: .8rem;
    padding: 3rem 13rem; }
  .description-card__title {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 206%;
    text-align: center;
    color: #FF9B00;
    margin-bottom: 2em; }
  .description-card__text {
    font-size: 1.6rem;
    line-height: 206%;
    text-align: center;
    color: #000000; }

@media screen and (max-width: 768px) {
  .description__price-category {
    margin: 0 auto;
    margin-bottom: 5rem; }
  .description-card__content {
    padding: 3rem 5rem; } }

@media screen and (max-width: 576px) {
  .description__image {
    padding: 1rem; }
  .description__list-item {
    font-size: 1.2rem;
    padding-left: 4.2rem;
    line-height: 2.25;
    margin-bottom: 1rem; }
    .description__list-item:last-child {
      margin-bottom: 0; }
    .description__list-item:before {
      width: 3rem;
      top: 1em; }
  .description-card__content {
    padding: 3rem 3rem; }
  .description-card__title {
    font-size: 1.4rem; }
  .description-card__text {
    font-size: 1.4rem; } }

.post-scriptum {
  position: relative; }
  .post-scriptum__help {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8.5rem; }
  .post-scriptum__title {
    margin-bottom: 6rem; }
  .post-scriptum__help-button {
    position: relative;
    margin-left: -2rem; }
    .post-scriptum__help-button:before {
      content: '';
      width: 8.5rem;
      height: 8.5rem;
      background-image: url("../img/line-1.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      left: calc(100% + 1.7rem);
      top: -5rem; }
  .post-scriptum__background-image {
    height: 96.5rem; }

.post-scriptum-card {
  max-width: 90rem;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  margin-bottom: 8.5rem; }
  .post-scriptum-card:before {
    content: '';
    width: 96.5%;
    height: 3.2rem;
    position: absolute;
    left: 1.75%;
    bottom: -1.6rem;
    background: #FFFFFF;
    box-shadow: 0 0.4rem 1.7rem rgba(0, 0, 0, 0.08);
    border-radius: .8rem;
    z-index: -1; }
  .post-scriptum-card__content {
    background: #FFFFFF;
    box-shadow: 0 0.4rem 1.7rem rgba(0, 0, 0, 0.08);
    border-radius: .8rem;
    padding: 3rem 13rem; }
  .post-scriptum-card__title {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 206%;
    color: #FF9B00;
    margin-bottom: 2em; }
  .post-scriptum-card__text {
    font-size: 1.6rem;
    line-height: 206%;
    color: #1F1F1F; }
  .post-scriptum-card__soc-links {
    display: flex;
    justify-content: center;
    margin-top: 4rem; }
  .post-scriptum-card__soc-link {
    display: block;
    width: 5rem;
    margin-right: 1rem; }
    .post-scriptum-card__soc-link img {
      width: 100%; }

@media screen and (max-width: 768px) {
  .post-scriptum-card__content {
    padding: 3rem 5rem; } }

@media screen and (max-width: 576px) {
  .post-scriptum__title {
    text-align: center; }
  .post-scriptum__help-button {
    margin-left: 0; }
  .post-scriptum-card__content {
    padding: 3rem 3rem; }
  .post-scriptum-card__title {
    font-size: 1.4rem; }
  .post-scriptum-card__text {
    font-size: 1.4rem; }
  .post-scriptum-card__soc-link {
    width: 4rem; } }
