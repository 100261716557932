.description {
    position: relative;
    &__background-image {
        height: 96.5rem;
        top: 25rem;
    }

    &__top {
        max-width: 90rem;
        margin: 0 auto;
        margin-bottom: 5.5rem;
        padding-left: 1.5rem;
    }
    &__title {
        margin-bottom: 1.5rem;
        .thin {
            font-weight: 400;
        }
    }
    &__list {
        font-size: 1.6rem;
        line-height: 286.5%;
        color: #1F1F1F;
        &-item {
            padding-left: 5.2rem + 3.7rem;

            position: relative;
            &:before {
                content: '';

                width: 5.2rem;
                height: .2rem;
                background: #FBBF62;

                position: absolute;
                left: 0;
                top: 1.4em;
            }
        }
    }

    &__image {
        background: #FFFFFF;
        box-shadow: .6rem .6rem 2.1rem rgba(91, 91, 91, 0.27);
        border-radius: 2.1rem;

        max-width: 88rem;
        margin: 0 auto;
        margin-bottom: 9rem;

        padding: 2rem;
        img {
            width: 100%;
        }
    }

    &__price-categories {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }
    &__price-category {
        margin-right: 5rem;
        margin-left: 5rem;
        margin-bottom: 7.5rem;
    }
}
.description-card {
    max-width: 90rem;
    margin: 0 auto;

    position: relative;
    z-index: 1;

    margin-bottom: 8.5rem;
    &:before {
        content: '';

        width: 96.5%;
        height: 3.2rem;

        position: absolute;
        left: 1.75%;
        bottom: -1.6rem;

        background: #FFFFFF;
        box-shadow: 0 .4rem 1.7rem rgba(0, 0, 0, 0.08);
        border-radius: .8rem;

        z-index: -1;
    }
    &__content {
        background: #FFFFFF;
        box-shadow: 0 .4rem 1.7rem rgba(0, 0, 0, 0.08);
        border-radius: .8rem;
        padding: 3rem 13rem;
    }
    &__title {
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 206%;
        text-align: center;
        color: #FF9B00;

        margin-bottom: 2em;
    }
    &__text {
        font-size: 1.6rem;
        line-height: 206%;
        text-align: center;
        color: #000000;
    }
}

@media screen and (max-width: 768px) {
    .description {
        &__price-category {
            margin: 0 auto;
            margin-bottom: 5rem;
        }
    }
    .description-card {
        &__content {
            padding: 3rem 5rem;
        }
    }
}
@media screen and (max-width: 576px) {
    .description {
        &__image {
            padding: 1rem;
        }
        &__list {
            &-item {
                font-size: 1.2rem;
                padding-left: 3rem + 1.2rem;
                line-height: 2.25;

                margin-bottom: 1rem;
                &:last-child {
                    margin-bottom: 0;
                }

                &:before {
                    width: 3rem;
                    top: 1em;
                }
            }
        }
    }
    .description-card {
        &__content {
            padding: 3rem 3rem;
        }
        &__title {
            font-size: 1.4rem;
        }
        &__text {
            font-size: 1.4rem;
        }
    }
}
