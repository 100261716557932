.request-2 {
    padding-top: 6rem;
    padding-bottom: 10.5rem;

    &__title {
        margin-bottom: 4.5rem;
    }
    &__content {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    position: relative;
    &__background {
        top: -15rem;
    }

    &__form {

    }
}
.request-2-director {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    margin-right: 15rem;
    &__image {
        width: 25rem;
        display: block;
        margin: 0 auto;
    }
    &__panel-wrapper {
        position: relative;
        z-index: 1;
        width: 52rem;
        &:after {
            content: '';

            width: 94%;
            height: 2.4rem;
            position: absolute;
            bottom: -1.2rem;
            left: 3%;

            background: #FFFFFF;
            box-shadow: 0 .4rem 1.7rem rgba(0, 0, 0, 0.08);
            border-radius: .8rem;

            z-index: -1;
        }
    }
    &__panel {
        background: #FFFFFF;
        box-shadow: 0 .4rem 1.7rem rgba(0, 0, 0, 0.08);
        border-radius: .8rem;

        padding: 3rem 5rem 3rem 7rem;

        text-align: right;
        &:before {
            content: '“';

            font-family: 'Lora', serif;
            font-weight: 700;
            font-size: 20rem;
            line-height: 206%;
            text-transform: uppercase;
            color: #FF9B00;

            line-height: 0;

            position: absolute;
            left: -4rem;
            top: 5rem;
        }
    }
    &__status {
        font-size: 1.6rem;
        line-height: 206%;
        color: #1F1F1F;
    }
    &__name {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 206%;
        color: #1F1F1F;
    }
}


@media screen and (max-width: 1240px) {
    .request-2-director {
        margin-right: 2.5rem;
    }
}
@media screen and (max-width: 992px) {
    .request-2 {
        &__content {
            flex-direction: column-reverse;
            align-items: center;
        }
        &__form {
            margin-bottom: 7.5rem;
        }
    }
    .request-2-director {
        margin-right: 0;
    }
}
@media screen and (max-width: 576px) {
    .request-2 {

    }
    .request-2-director {
        &__image {
            max-width: 25rem;
        }
        &__panel-wrapper {
            width: auto;
            max-width: 40rem;
        }
        &__panel {
            padding: 2rem 2rem 2rem 3rem;
            &:before {
                font-size: 12rem;
                top: 1rem;
                left: 1rem;
            }
        }
        &__status {
            font-size: 1.4rem;
        }
        &__name {
            font-size: 1.4rem;
        }
    }
}
