.certificates {
    padding-top: 7.5rem;
    padding-bottom: 5rem;

    > .page-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__content {
        width: 45rem;
    }
    &__title {
        margin-bottom: 1.4rem;
    }
    &__subtitle {

    }

    &__items {
        background: #FFFFFF;
        box-shadow: .6rem .6rem 2.1rem rgba(91, 91, 91, 0.27);
        border-radius: 1.4rem;

        padding: 2.7rem 4rem (2.7rem - 1.4rem);

        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        justify-content: space-between;

        max-width: 58rem;
    }
}
.certificate {
    margin-bottom: 1.4rem;
    &__image {
        width: 100%;
    }

    &-1 {
        width: 32%;
    }
    &-2 {
        width: 32%;
    }
    &-3 {
        width: 32%;
    }
    &-4 {
        width: 32%;
    }
    &-5 {
        width: 32%;
    }
    &-6 {
        width: 32%;
    }
    &-7 {
        width: 64%;
    }
}

@media screen and (max-width: 1240px) {
    .certificates {
        &__items {
            max-width: 40rem;
        }
    }
    .certificate {
        &-1 {
            width: 49%;
        }
        &-2 {
            width: 49%;
        }
        &-3 {
            width: 49%;
        }
        &-4 {
            width: 49%;
        }
        &-5 {
            width: 49%;
        }
        &-6 {
            width: 49%;
        }
        &-7 {
            width: 100%;
        }
    }
}
@media screen and (max-width: 992px) {
    .certificates {
        > .page-container {
            flex-direction: column;
        }
        &__content {
            margin-bottom: 5rem;
        }
        &__items {
            max-width: 53rem;
        }
    }
    .certificate {
        &-1 {
            width: 32%;
        }
        &-2 {
            width: 32%;
        }
        &-3 {
            width: 32%;
        }
        &-4 {
            width: 32%;
        }
        &-5 {
            width: 32%;
        }
        &-6 {
            width: 32%;
        }
        &-7 {
            width: 64%;
        }
    }
}
@media screen and (max-width: 576px) {
    .certificates {
        &__content {
            width: auto;
        }
        &__items {
            max-width: 35rem;
            padding: 1.75rem 1.5rem .3rem;
        }
    }
    .certificate {
        &-1 {
            width: 49%;
        }
        &-2 {
            width: 49%;
        }
        &-3 {
            width: 49%;
        }
        &-4 {
            width: 49%;
        }
        &-5 {
            width: 49%;
        }
        &-6 {
            width: 49%;
        }
        &-7 {
            width: 100%;
        }
    }
}
