.js-form {
  &-loading {

  }
  &-success {
    display: none;
  }
  &-error {
    .js-form__error-message {
      display: block;
    }
  }

  &__success-message {
    display: none;
  }
  &__error-message {
    display: none;

    width: 100%;
font-weight: 500;
    font-size: 1.6rem;
    text-align: left;
    color: red;
  }

    .button {
        &:disabled {
            opacity: .5;
            cursor: not-allowed;
        }
    }
}
