.reviews {
    padding-top: 6rem;
    padding-bottom: 4rem;
    &__title {
        margin-bottom: 5rem;
    }
    &__carousel {
        width: 27rem + 6rem + 27rem + 6rem + 27rem;
        margin: 0 auto;
        .owl-stage {
            display: flex;
        }
        .owl-stage-outer {
            overflow: visible;
        }
        .owl-item {
            opacity: 0;
            transition: opacity .25s;
            &.active {
                opacity: 1;
            }
        }

        &-item {
            height: 100%;
        }

        .owl {
            &-prev,
            &-next {
                width: 6.7rem;
                height: 6.7rem;
                background: #E1E1E1 !important;
                border-radius: 50%;

                display: flex;
                align-items: center;
                justify-content: center;

                position: absolute;
                top: 21rem;
            }
            &-prev {
                right: calc(100% + 6rem);
                img {
                    transform: scale(-1, 1);
                }
            }
            &-next {
                left: calc(100% + 6rem);
            }
        }
    }
}
.review {
    width: 27rem;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    &__phone {
        width: 26rem;
        height: 53rem;

        background-image: url('../img/phone.jpg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        position: relative;

        margin-bottom: 6.5rem;
        margin-left: 1rem;
    }
    &__image {
        position: absolute;

        top: 6.5rem;
        left: .9rem;

        width: 24.25rem !important;
        height: 39.5rem;
    }

    &__quote {
        flex-grow: 1;
        margin-left: 2rem;

        &-content {
            background: #FFFFFF;
            box-shadow: 0 .4rem 1.7rem rgba(0, 0, 0, 0.08);
            border-radius: .8rem;
            padding: 3.5rem 1.5rem 3.5rem 4rem;
            height: 100%;
        }
        &-text {
            font-size: 1.3rem;
            line-height: 206%;
            color: #1F1F1F;
        }

        position: relative;
        z-index: 1;
        &:before {
            content: '“';

            font-family: Lora, serif;
            font-weight: 700;
            font-size: 20rem;
            text-transform: uppercase;
            color: #FF9B00;
            line-height: 0;

            position: absolute;
            left: -.3em;
            top: .25em;
        }
        &:after {
            content: '';

            width: 90%;
            height: 2.2rem;

            position: absolute;
            left: 5%;
            bottom: -1.1rem;

            background: #FFFFFF;
            box-shadow: 0 .4rem 1.7rem rgba(0, 0, 0, 0.08);
            border-radius: .8rem;

            z-index: -1;
        }
    }
}

@media screen and (max-width: 1240px) {
    .reviews {
        &__carousel {
            width: 27rem + 6rem + 27rem;
        }
    }
}
@media screen and (max-width: 992px) {
    .reviews {
        &__title {
            text-align: center;
        }
        &__carousel {
            .owl {
                &-prev {
                    right: calc(100% - 1rem);
                }
                &-next {
                    left: calc(100% - 1rem);
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .reviews {
        &__carousel {
            width: 100%;
            .owl {
                &-prev {
                    right: calc(50% + 13.5rem + 4rem);
                }
                &-next {
                    left: calc(50% + 13.5rem + 4rem);
                }
            }
        }
    }
}
@media screen and (max-width: 576px) {
    .reviews {
        &__carousel {
            .owl {
                &-prev,
                &-next {
                    width: 4rem;
                    height: 4rem;
                    img {
                        width: 30%;
                    }
                }
                &-prev {
                    right: calc(50% + 12rem + 1rem);
                }
                &-next {
                    left: calc(50% + 12rem + 1rem);
                }
            }
        }
    }
    .review {
        width: 24rem;
        &__phone {
            width: 24rem;
            height: 49rem;
            margin-left: 0;
        }
        &__image {
            width: 22.5rem !important;
            height: 36.25rem;

            top: 6rem;
            left: .8rem;
        }
    }
}

@media screen and (max-width: 360px) {
    .reviews {
        &__title {
            margin-bottom: 6rem;
        }
        &__carousel {
            .owl {
                &-prev,
                &-next {
                    top: -5rem;
                }
                &-prev {
                    right: calc(50% + 1rem);
                }
                &-next {
                    left: calc(50% + 1rem);
                }
            }
        }
    }
    .review {
        &__phone {
            margin-bottom: 2.5rem;
        }
    }
}
