.steps {
    padding-top: 7rem;
    padding-bottom: 8rem;
    > .page-container {
        width: 100%;
        height: 66.5rem;
        position: relative;
        transition: height .25s;
    }
}
.step {
    display: none;
    //&-1 {
    //    border-top: .5rem solid #000;
    //}
    //border-bottom: .5rem solid #000;

    position: absolute;
    padding: 0 2rem;
    left: 0;
    top: 0;
    width: 100%;

    &__inner {
        display: flex;
        flex-direction: column;
        //height: 66.5rem;

        width: 100%;
        height: 100%;
    }
    &__content {
        flex-grow: 1;
    }


    &.active {
        display: block;
    }

    &__title {

    }
    &__content {

    }
    &__text-block {
        padding: 3.8rem 6rem;

        background: #FFFFFF;
        box-shadow: 0 .4rem 1.7rem rgba(0, 0, 0, 0.08);
        border-radius: .84rem;
    }
    &__list {

    }
    &__list-title {
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 206%;
        color: #1F1F1F;

        margin-bottom: 1.5em;
        padding-lefT: 2.75rem + 2rem;
    }
    &__list-item {
        font-size: 1.2rem;
        line-height: 206%;
        color: #1F1F1F;

        padding-left: 2.75rem + 2rem;
        margin-bottom: 1.5em;
        &:last-child {
            margin-bottom: 0;
        }

        position: relative;
        &:before {
            content: '';

            width: 2.75rem;
            height: .4rem;
            background: #FF9B00;

            position: absolute;
            top: calc(50% - .2rem);
            left: 0;
        }
    }

    &__counter {
        margin-top: 4.3rem;
        &-mobile {
            display: none;
        }
        &-main {
            display: flex;
            align-items: center;
        }
        &-button {
            .button__layer {
                &_top {
                    background-image: url(../img/button-texture-gray.jpg);
                }
                &_bottom {
                    background: #8F8F8F;
                }
            }
        }
        &-text {
            font-family: 'Lora', serif;
            font-weight: 700;
            font-size: 4rem;
            line-height: 206%;
            color: #737373;

            margin-left: 4.5rem;
        }
    }
    &__previous {
        margin-top: 2rem;

        font-weight: 500;
        font-size: 1.8rem;
        line-height: 206%;
        color: #8F8F8F;
    }
}

.step-1 {

    &__title {
        margin-bottom: 8rem;
    }
    &__content {
        position: relative;

        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    &__text-block {
        max-width: 51rem;
    }
    &__image {
        width: 32rem;
        z-index: -1;

        position: absolute;
        bottom: 0;
        right: 36rem
    }
}

.step-2 {

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        position: relative;
    }
    &__title {
        margin-bottom: 8.5rem;
    }
    &__left {
        width: 51rem;
    }
    &__text-block {
        max-width: 51rem;
    }
    &__image {
        height: 100%;

        position: absolute;
        right: 15%;
        bottom: 0;
        img {
            max-height: 100%;
        }
    }
}

.step-3 {
    &__title {
        margin-bottom: 7rem;
    }
    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__list {
        max-width: 51rem;
    }
    &__list-item {
        background: #FFFFFF;
        box-shadow: 0 .4rem 1.7rem rgba(0, 0, 0, 0.08);
        border-radius: .8rem;

        margin-bottom: 2.8rem;

        display: block;
        padding: 2rem 5rem 2rem (5rem + 4.75rem);
        &:before {
            left: 5rem;
        }
    }
    &__gallery {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 3rem;
        grid-column-gap: 2rem;

        max-width: 50rem;
    }
    &__gallery-image {
        width: 100%;
        &:first-child {
            grid-column: 1/4;
        }
    }
}
.step-4 {
    &__title {
        margin-bottom: 8.5rem;
    }
    &__content {
        display: flex;
        justify-content: space-between;
    }
    &__text-block {
        max-width: 51rem;
    }
    &__image {
        max-width: 50rem;
    }
}
.step-5 {
    &__title {
        margin-bottom: 4rem;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__text-block {
        margin-bottom: 3.5rem;
        max-width: 51rem;
    }
    &__left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    &__form {
        display: flex;
        align-items: start;

        position: relative;
        margin-bottom: 6rem;
        &-input {
            margin-right: 2.5rem;
        }
        &-button {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &-hint {
            font-size: 1rem;
            line-height: 206%;
            color: #AFAFAF;

            max-width: 20.5rem;

            position: absolute;
            top: calc(100% + 3rem);
            right: 2rem;
        }
    }
    &__error-message {
        text-align: center;
        margin-top: 1rem;
    }
    &__image {
        &-mobile {
            display: none;
        }
        img {
            border-radius: 4.5rem;
            width: 50rem;
        }
    }
}

@media screen and (max-width: 1240px) {
    .step {

    }
    .step-1 {
        &__image {
            display: none;
        }
    }
    .step-2 {
        &__image {
            right: 0;
        }
    }
    .step-3 {
        &__content {
            align-items: flex-start;
        }
        &__left {
            margin-right: 2.5rem;
        }
    }
    .step-4 {
        &__content {
            align-items: flex-start;
        }
        &__left {
            margin-right: 2.5rem;
        }
    }
    .step-5 {
        &__left {
            width: 61rem;
            margin-right: 2.5rem;
        }
        &__image {
            width: auto;
            margin-left: -(10rem);
        }
    }
}
@media screen and (max-width: 992px) {
    .step {
        //height: 110rem;
        &__title {
            padding: 0;
            &:after,
            &:before {
                display: none;
            }
            br {
                display: none;
            }
            text-align: center;

            margin-bottom: 5rem !important;
        }
        &__text-block {
            padding: 3.8rem 3rem;
            margin-bottom: 4rem;
        }
        &__inner {
            height: 100%;
        }

        &__counter {
            display: none;
            &-mobile {
                display: block;
            }
        }
    }
    .step-1 {
        &__content {
            flex-direction: column;
            align-items: center;
        }
        &__form {

        }
    }
    .step-2 {
        &__content {
            flex-direction: column;
            align-items: center;
        }
        &__image {
            position: static;
            height: 48.5rem;
            img {
                height: 100%;
            }
        }
    }
    .step-3 {
        &__content {
            flex-direction: column;
            align-items: center;
        }
        &__left {
            margin-right: 0;
        }
        &__list {
            margin-bottom: 4rem;
        }
        &__gallery {
            max-width: 45rem;
        }
    }
    .step-4 {
        &__content {
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
        }
        &__left {
            margin-right: 0;
        }
        &__image {

        }
    }
    .step-5 {
        &__content {
            flex-direction: column-reverse;
            justify-content: flex-start;
            align-items: center;
        }
        &__left {
            margin-right: 0;
            align-items: center;
        }
        &__image {
            margin-left: 0;
            margin-bottom: 5rem;

            display: none;
            &-mobile {
                display: block;
            }
        }
        &__previous {
            align-self: flex-start;
        }
    }
}
@media screen and (max-width: 768px) {
    .step-5 {
        &__left {
            width: auto;
        }
        &__form {
            flex-direction: column;
            align-items: center;
            margin-bottom: 0;
            &-input {
                margin-right: 0;
                margin-bottom: 1.5rem;
            }
            &-button {
                margin-bottom: 1.5rem;
            }
            &-hint {
                position: static;
            }
        }
        &__image {
            max-width: 51rem;
        }
        &__previous {
            align-self: center;
        }
    }
}
@media screen and (max-width: 576px) {
    .step {
        padding: 0 1rem;
        height: auto;
        &__title {
            margin-bottom: 3rem !important;
        }
        &__counter {
            &-text {
                font-size: 3rem;
                margin-left: 1rem;
            }
        }
    }
    .step-2 {
        &__left {
            width: auto;
        }
        &__image {

        }
    }
    .step-3 {
        &__gallery {
            grid-gap: 1rem;
        }
        &__list {
            &-item {
                padding: 2rem 2rem 2rem (2rem + 4.75rem);
                &:before {
                    left: 2rem;
                }
            }
        }
    }
    .step-5 {
        &__left {
            flex-direction: column;
            align-items: center;
        }
    }
}
