.price-category {
    width: 50rem;
    &__title {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 249.5%;
        color: #1F1F1F;

        text-align: center;
        margin-bottom: 4rem;
    }
    &__table {
        width: 100%;

        font-size: 1.6rem;
        line-height: 249.5%;
        color: #1F1F1F;

        position: relative;
        &:after {
            content: '';

            width: 11.5rem;
            height: calc(100% + 2rem - 4.1rem - 1rem);

            background: #FF9B00;
            box-shadow: 0 .4rem 1.5rem rgba(0, 0, 0, 0.13);
            border-radius: 1rem;

            position: absolute;
            right: .25rem;
            top: -2rem;
            z-index: -1;
        }

        tr {

        }
        th,td {
            text-align: center;
            &:nth-child(2) {
                width: 16.5rem;
                padding-left: .5rem;
                padding-right: 4.5rem;
            }
            &:last-child {
                color: #FFFFFF;
                padding: 0 1rem;
                width: 11.5rem;
            }
        }
        th {
            font-weight: 700;
        }
        td {
            &:first-child {
                text-align: left;
            }
        }
    }
    &__table-end-row {
        td {
            padding-top: 1rem !important;
            &:first-child {
                font-weight: 700;
                text-transform: uppercase;
            }
            &:nth-child(2) {
                text-decoration: line-through;
            }
            &:last-child {
                font-weight: 900;
                color: #FF9B00;
            }
        }
    }
}
@media screen and (max-width: 576px) {
    .price-category {
        max-width: 50rem;
        width: 100%;
        &__table {
            font-size: 1.2rem;
            &:after {
                content: '';

                width: 8.5rem;
                height: calc(100% + 1rem - 4.1rem - .5rem);

                background: #FF9B00;
                box-shadow: 0 .4rem 1.5rem rgba(0, 0, 0, 0.13);
                border-radius: 1rem;

                position: absolute;
                right: .25rem;
                top: -1rem;
                z-index: -1;
            }
            th,td {
                &:nth-child(2) {
                    width: 10rem;
                    padding-right: .5rem;
                }
                &:last-child {
                    width: 8.5rem;
                }
            }
        }
    }
}
