.lines {
    position: absolute;

    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    background-image: url(../img/line.png);
    background-size: 20rem auto;
    background-position: 0 0;
    background-repeat: repeat;

    z-index: 1;
}
