.quiz {
    display: inline-block;
    position: relative;
    padding-left: 30rem + 4rem;
    min-height: 62rem;

    &__sidebar {
        background: #FFFFFF;
        box-shadow: 0 .4rem 1.6rem rgba(0, 0, 0, 0.08);
        border-radius: 1.2rem;

        position: absolute;
        left: 0;
        top: 0;

        width: 30rem;
        height: 62rem;
        padding: 4rem 3rem;

        border-left: 1rem solid #FF9B00;

        &-title {
            font-weight: 700;
            font-size: 13px;
            line-height: 206%;
            color: #1F1F1F;

            margin-bottom: 2.5rem;
        }
    }
    &__present {
        margin-bottom: 2rem;
        &:last-child {
            margin-bottom: 0;
        }
        &-text {
            font-size: 1.3rem;
            line-height: 206%;
            color: #1F1F1F;
            .bold {
                font-weight: 700;
            }
            .orange {
                color: #FF9B00;
            }
        }
        &-image {
            margin-left: 2.5rem;
        }

        &-1 {
            .quiz__present-image {
                margin-left: 1.5rem;
            }
        }
        &-2 {

        }
        &-3 {
            .quiz__present-image {
                margin-left: 1rem;
            }
        }
    }

    &__main {
        width: 22rem + 3.5rem + 22rem + 5rem;
        height: 100%;

        display: flex;
        flex-direction: column;
    }

    &__progressbar {
        display: flex;
        align-items: center;

        margin-bottom: 3rem;
        &-percent {
            font-size: 1.6rem;
            line-height: 206%;
            color: #1F1F1F;

            margin-right: 1.5rem;
        }
        &-background {
            height: 1.9rem;
            flex-grow: 1;
            max-width: 44rem;

            background: #EDEDED;
            border-radius: 1.7rem;

            position: relative;
        }
        &-body {
            position: absolute;
            left: 0;
            width: 0;
            top: 0;
            height: 100%;
            border-radius: 1.7rem;

            transition: width .25s;

            background-image: url('../img/button-texture.jpg');
            background-position: center;
            background-size: cover;

            &:after {
                content: "";

                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                background: linear-gradient(
                        -45deg,
                        transparent 47.5%,
                        rgba(255, 255, 255, .8) 47.5%,
                        rgba(255, 255, 255, .8) 52.5%,
                        transparent 52.5%,
                        transparent
                );
                z-index: 1;
                background-size: 2rem 1.9rem;
                animation: move 2s linear infinite;
                border-radius: 2rem .8rem .8rem 2rem;
                overflow: hidden;
            }
        }
    }
    @keyframes move {
        0% {
            background-position: 0 0;
        }
        100% {
            background-position: 2rem 1.9rem;
        }
    }

    &__tab {
        display: none;
        &.active {
            display: block;
        }
    }

    &__title {
        font-family: Lora, serif;
        font-size: 2.2rem;
        line-height: 206%;
        text-transform: uppercase;
        color: #000000;

        margin-bottom: 3rem;

        .bold {
            font-weight: 700;
        }
        .orange {
            color: #ff9b00;
        }
    }

    &__form {
        position: relative;
        flex-grow: 1;
    }
    &__tab {
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    &__fieldset {
        padding-right: 5rem;
        &_cards {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 3.5rem;
        }
    }
    &__field {
        input {
            display: none;
        }
    }

    &__back {
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 206%;
        color: #8F8F8F;

        position: absolute;
        top: calc(100% + 2.4rem);
        left: 0;

        cursor: pointer;
        transition: opacity .25s;

        &:disabled {
            cursor: not-allowed;
            opacity: .7;
        }
    }
}

.quiz-card {
    background: #FFFFFF;
    box-shadow: 0 .4rem 1.6rem rgba(0, 0, 0, 0.08);
    border-radius: 1.2rem;

    width: 22rem;
    display: flex;
    flex-direction: column;

    cursor: pointer;

    &__image {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            max-width: 100%;
        }
    }
    &__text {
        height: 4.35rem;

        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1.25;
        color: #1F1F1F;
        text-align: center;

        display: flex;
        align-items: center;
        justify-content: center;

        padding: 0 1rem;
    }
}
.quiz-panel {
    display: block;

    background: #FFFFFF;
    box-shadow: 0 .4rem 1.6rem rgba(0, 0, 0, 0.08);
    border-radius: 1.2rem;

    padding: 1.5rem 2.2rem;

    cursor: pointer;

    margin-bottom: 1.2rem;
    &:last-child {
        margin-bottom: 0;
    }

    &_centered {
        text-align: center;
    }
    &_with-image {
        display: flex;
        align-items: center;
    }

    &__image {
        margin-right: 3.25rem;
        width: 3.5rem;
    }
    &__text {
        font-size: 1.3rem;
        line-height: 206%;
        color: #1F1F1F;
    }
}
.quiz-success {
    &__form {
        position: relative;
        z-index: 1;

        margin-bottom: 7.5rem;
        &:after {
            content: '';

            width: 96.5%;
            height: 2rem;

            position: absolute;
            bottom: -1rem;
            left: 1.75%;

            background: #FFFFFF;
            box-shadow: 0 .4rem 1.7rem rgba(0, 0, 0, 0.08);
            border-radius: .8rem;

            z-index: -1;
        }
    }
    &__form-content {
        background: #FFFFFF;
        box-shadow: 0 .4rem 1.7rem rgba(0, 0, 0, 0.08);
        border-radius: .8rem;

        padding: 3rem 3rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    &__input {
        width: 30rem;
        margin-bottom: 1rem;
    }
    &__button {
        width: 30rem;
    }
    &__error-message {
        text-align: center;
        margin-top: 1rem;
    }
    &__hint {
        font-size: 1rem;
        line-height: 206%;
        color: #AFAFAF;

        position: absolute;
        top: calc(100% + 1.4rem);
        left: 4.5rem;
    }

    &__process {
        display: flex;
        align-items: center;
        &-image {
            position: relative;
            width: 12rem;
            height: 12rem;


            span {
                font-family: Lora, serif;
                font-weight: 700;
                font-size: 2.2rem;
                line-height: 206%;
                text-transform: uppercase;
                color: #000000;
                text-align: center;

                width: 100%;
                height: 100%;
                background-image: url('../img/button-texture.jpg');
                background-size: cover;
                background-position: center;

                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;

                position: relative;
                z-index: -1;
            }
            canvas {
                z-index: 1;
            }


            margin-right: 2rem;
            img {
                position: absolute;
                left: 50%;
                top: calc(50% + .4rem);
                transform: translate(-50%, -50%);
            }
            span {
                font-family: Lora, serif;
                font-weight: 700;
                font-size: 2.2rem;
                text-transform: uppercase;
                color: #000000;

                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
        &-text {
            font-size: 1.3rem;
            line-height: 206%;
            color: #1F1F1F;
        }
    }
}

@media screen and (max-width: 992px) {
    .quiz {
        padding-left: 0;
        &__sidebar {
            position: static;
            height: auto;
            width: auto;

            margin-bottom: 5rem;
        }
        &__presents {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
        }
        &__present {
            display: flex;
            align-items: center;
            &-image {
                flex: 0 0 7.5rem;

                display: flex;
                align-items: center;
                justify-content: center;

                margin-left: 0;
                margin-right: 2.5rem;
            }

            &-1,
            &-2,
            &-3 {
                .quiz__present-image {
                    margin-left: 0;
                }
            }
        }

        &__main {
            margin: 0 auto;
            height: auto;
        }
    }
}
@media screen and (max-width: 768px) {
    .quiz {
        &__main {
            width: 100%;
            max-width: 47.5rem;
        }
        &__fieldset {
            padding-right: 0;
        }
    }
}
@media screen and (max-width: 576px) {
    .quiz {
        &__title {
            font-size: 1.8rem;
        }
        &__fieldset {
            &_cards {
                grid-gap: 2rem;
            }
        }
        &__back {
            font-size: 1.4rem;
        }
    }
    .quiz-card {
        width: 100%;
        &__image {
            &_question-mark {
                img {
                    width: 35%;
                }
            }
        }
    }
    .quiz-success {
        &__title {
            br {
                display: none;
            }
        }
        &__input {
            width: 100%;
            max-width: 30rem;
        }
        &__button {
            width: 100%;
            max-width: 30rem;
        }
    }
}
@media screen and (max-width: 400px) {
    .quiz {
        &__fieldset {
            &_cards {
                grid-gap: 1rem;
            }
        }
    }
    .quiz-panel {
        &__image {
            margin-right: 1.5rem;
        }
    }
    .quiz-success {
        &__form-content {
            padding: 3rem 1.5rem;
        }
    }
}
